// import package
import React, { useEffect, useState } from "react";

// import material ui
import { Store, Accessibility } from "@material-ui/icons";
import { Icon } from "@material-ui/core";

// import componet
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import config from "../../actions/config";
import Typography from "@material-ui/core/Typography";
import ApexCharts from "react-apexcharts";
import "./Dash.css";
import { DataGrid } from "@material-ui/data-grid";
// import action
import {
  getDashboardCount,
  getTradeCount,
  getTokenCount,
  getBidderCount,
  getAdminRenewal,
  getDashboardDetails,
  getTopCollection
} from "../../actions/dashboard";
import moment from "moment";

const initialFormValue = {
  bnbTot: 0,
  wBnbTot: 0,
  bnb24: 0,
  bnb3d: 0,
  wBnb24: 0,
  wBnb3d: 0
};

const DashboardCount = (props) => {
  // props
  const { classes } = props;

  // state
  const [countData, setCountData] = useState(initialFormValue);
  const [tradeCount, setTradeCount] = useState(0);
  const [tokenCount, setTokenCount] = useState(0);
  const [bidderCount, setBidderCount] = useState(0);
  const [adminIncomes, setAdminIncomes] = useState([]);
  const [adminIncomesTok, setAdminIncomesTok] = useState([]);
  const [topCollBnb, setTopCollBnb] = useState([]);
  const [topCollWbnb, setTopCollWbnb] = useState([]);
  const [options, setOption] = useState({
    chart: {
      type: "bar",
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"]
    },
    xaxis: {
      categories: [
        // "25 Jan",
        // "26 Jan",
        // "27 Jan",
        // "28 Jan",
        // "29 Jan",
        // "30 Jan",
        // "31 Jan",
        // "01 Feb",
        // "02 Feb"
      ]
    },
    yaxis: {
      title: {
        text: "$ (thousands)"
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        }
      }
    }
  });
  const [series, setSeries] = useState([
    // {
    //   name: "BNB",
    //   data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    // },
    // {
    //   name: "wBNB",
    //   data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
    // }
  ]);
  console.log(series, "series");
  // const [options1, setOption1] = useState({
  //   chart: {
  //     type: "area",
  //     height: 350,
  //     zoom: {
  //       enabled: false
  //     }
  //   },
  //   dataLabels: {
  //     enabled: false
  //   },
  //   stroke: {
  //     curve: "straight"
  //   },

  //   title: {
  //     text: "Fundamental Analysis of Stocks",
  //     align: "left"
  //   },
  //   subtitle: {
  //     text: "Price Movements",
  //     align: "left"
  //   },
  //   labels: [13, 15, 16],
  //   xaxis: {
  //     type: "datetime"
  //   },
  //   yaxis: {
  //     opposite: true
  //   },
  //   legend: {
  //     horizontalAlign: "left"
  //   }
  // });
  // const [series1, setSeries1] = useState([
  //   {
  //     name: "STOCK ABC",
  //     data: [12, 34, 56]
  //   },
  //   {
  //     name: "STOCK DEF",
  //     data: [12, 34, 56]
  //   }
  // ]);

  // function generateDayWiseTimeSeries(baseTimestamp, numberOfDays, valueRange) {
  //   const seriesData = [];

  //   for (let i = 0; i < numberOfDays; i++) {
  //     const timestamp = baseTimestamp + i * 24 * 60 * 60 * 1000; // Adding 24 hours in milliseconds for each day
  //     const value = getRandomValue(valueRange.min, valueRange.max);

  //     seriesData.push([timestamp, value]);
  //   }

  //   return seriesData;
  // }

  // function getRandomValue(min, max) {
  //   return Math.floor(Math.random() * (max - min + 1)) + min;
  // }

  const fetchTokenCnt = async () => {
    const { status, loading, error, result } = await getTokenCount();
    if (status == "success") {
      setTokenCount(result.tokenData);
      // setCountData({ ...countData, ...result })
    }
  };

  const fetchBidderCnt = async () => {
    const { status, loading, error, result } = await getBidderCount();
    if (status == "success") {
      setBidderCount(result.bid);
      // setCountData({ ...countData, ...result })
    }
  };

  const adminRenewalData = async () => {
    let bnbArr = [];
    let wbnbArr = [];
    let bnbAmt = [];
    let wBnbAmt = [];
    let dates = [];
    const data = await getAdminRenewal();
    console.log(data?.result, "fdfdsfsdfsdfsdf");
    for (const dd of data?.result) {
      if (!dd.isBid) {
        bnbArr.push(dd);
        // dd.name = "BNB";
        bnbAmt.push(dd.fees);
      } else {
        wbnbArr.push(dd);
        dd.name = "WBNB";
        wBnbAmt.push(dd.fees);

        // wBnbAmt.data.push(dd.fees);
      }
      dates.push(moment(dd.createdAt).format("ll"));
    }
    await bnbArr.map((el, i) => {
      el.id = i + 1;
    });
    await wbnbArr.map((el, i) => {
      el.id = i + 1;
    });
    // await bnbAmt.map((ee) => {
    //   ee.name == "BNB";
    // });
    setAdminIncomes(bnbArr);
    setAdminIncomesTok(wbnbArr);
    console.log(bnbAmt, "bnbAmt", dates);
    setSeries([
      { name: "BNB", data: bnbAmt },
      { name: "WBNB", data: wBnbAmt }
    ]);
    // setOption(...options,})
    setOption((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: dates
      }
    }));
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 8) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const getDashDetails = async () => {
    const data = await getDashboardDetails();
    console.log(data, "datadatadatadata123");
    setCountData({
      ...countData,
      bnbTot: data?.result?.bnbTotal ? data?.result?.bnbTotal : 0,
      wBnbTot: data?.result?.wbnbTotal ? data?.result?.wbnbTotal : 0,
      bnb24: data?.result?.bnb24 ? data?.result?.bnb24 : 0,
      bnb3d: data?.result?.Bnb3day ? data?.result?.Bnb3day : 0,
      wBnb24: data?.result?.wbnb24 ? data?.result?.wbnb24 : 0,
      wBnb3d: data?.result?.wBnb3day ? data?.result?.wBnb3day : 0
    });
  };

  const getTopColl = async () => {
    let bnbArr = [];
    let wbnbArr = [];
    const data = await getTopCollection();
    console.log(data.result?.data, "datadatad___ata");
    let mergeArr = [...data.result?.data, ...data.result?.data1];
    for (const dd of mergeArr) {
      if (dd.actiontype == "Purchase") {
        bnbArr.push(dd);
      } else {
        wbnbArr.push(dd);
      }
    }
    await bnbArr.map((el, i) => {
      el.id = i + 1;
    });
    await wbnbArr.map((el, i) => {
      el.id = i + 1;
    });
    setTopCollBnb(bnbArr);
    setTopCollWbnb(wbnbArr);
  };

  console.log(countData, "countData");
  useEffect(() => {
    fetchBidderCnt();
    fetchTokenCnt();
    adminRenewalData();
    getDashDetails();
    getTopColl();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "fromAddress",
      headerName: "From Address",
      width: 200,
      valueGetter: (params) => halfAddrShow(params.row.fromAddress)
    },
    {
      field: "nftID",
      headerName: "NFT ID",
      width: 150
    },
    {
      field: "fees",
      headerName: "Admin Fee",
      width: 160
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 190,
      valueGetter: (params) => moment(params.row.createdAt).format("lll")
    }
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue(params.id, 'firstName') || ''} ${
    //       params.getValue(params.id, 'lastName') || ''
    //     }`,
    // },
  ];
  const columns1 = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "fromAddress",
      headerName: "From Address",
      width: 200,
      valueGetter: (params) => halfAddrShow(params.row.fromAddress)
    },
    {
      field: "nftID",
      headerName: "NFT ID",
      width: 150
    },
    {
      field: "fees",
      headerName: "Admin Fee",
      width: 160
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 190,
      valueGetter: (params) => moment(params.row.createdAt).format("lll")
    }
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue(params.id, 'firstName') || ''} ${
    //       params.getValue(params.id, 'lastName') || ''
    //     }`,
    // },
  ];
  const columns2 = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "_id",
      headerName: "Address",
      width: 150,
      valueGetter: (params) => halfAddrShow(params.row._id)
    },
    {
      field: "contractName",
      headerName: "Collection",
      width: 170
    },
    {
      field: "totalVol",
      headerName: "Value (BNB)",
      width: 170
    }
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue(params.id, 'firstName') || ''} ${
    //       params.getValue(params.id, 'lastName') || ''
    //     }`,
    // },
  ];
  const columns3 = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "_id",
      headerName: "Address",
      width: 150,
      valueGetter: (params) => halfAddrShow(params.row._id)
    },
    {
      field: "contractName",
      headerName: "Collection",
      width: 170
    },
    {
      field: "totalVol",
      headerName: "Value (BNB)",
      width: 170
    }
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue(params.id, 'firstName') || ''} ${
    //       params.getValue(params.id, 'lastName') || ''
    //     }`,
    // },
  ];
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Typography
            variant="h6"
            component="h2"
            style={{ marginBottom: "20px", float: "left", color: "#5a00f5" }}
          >
            Admin Revenue Modal (BNB)
          </Typography>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} style={{ marginTop: "20px" }}>
          <a href={`${config.frontUrl}dopomainnftniarus/TokenList`}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory}>Total Revenue</p>
                <h3 className={classes.cardTitle}>
                  {(countData?.bnbTot).toFixed(5)}
                </h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </a>
          <a href={`${config.frontUrl}dopomainnftniarus/bidlist`}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>info_outline</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Last 24 Hr (Revenue)</p>
                <h3 className={classes.cardTitle}>
                  {(countData?.bnb24).toFixed(5)}
                </h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </a>
          <a href={`${config.frontUrl}dopomainnftniarus/bidlist`}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>info_outline</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Last 3 Days (Revenue)</p>
                <h3 className={classes.cardTitle}>
                  {(countData?.bnb3d).toFixed(5)}
                </h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </a>
        </GridItem>
        <GridItem xs={12} sm={9} md={9}>
          <Card>
            <CardHeader>
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={adminIncomes}
                  columns={columns}
                  pageSize={5}
                  // checkboxSelection
                  // disableSelectionOnClick
                />
              </div>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Typography
            variant="h6"
            component="h2"
            style={{ marginBottom: "12px", float: "left", color: "#5a00f5" }}
          >
            Admin Revenue Modal (WBNB)
          </Typography>
        </GridItem>
        <GridItem xs={12} sm={9} md={9}>
          <Card>
            <CardHeader>
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={adminIncomesTok}
                  columns={columns1}
                  pageSize={5}
                  // checkboxSelection
                  // disableSelectionOnClick
                />
              </div>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} style={{ marginTop: "20px" }}>
          <a href={`${config.frontUrl}dopomainnftniarus/TokenList`}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory}>Total Revenue</p>
                <h3 className={classes.cardTitle}>
                  {(countData?.wBnbTot).toFixed(5)}
                </h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </a>
          <a href={`${config.frontUrl}dopomainnftniarus/bidlist`}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>info_outline</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Last 24 Hr (Revenue)</p>
                <h3 className={classes.cardTitle}>
                  {(countData?.wBnb24).toFixed(5)}
                </h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </a>
          <a href={`${config.frontUrl}dopomainnftniarus/bidlist`}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>info_outline</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Last 3 Days (Revenue)</p>
                <h3 className={classes.cardTitle}>
                  {(countData?.wBnb3d).toFixed(5)}
                </h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </a>
        </GridItem>
      </GridContainer>

      <GridContainer style={{ marginBottom: "20px" }}>
        {/* <GridItem xs={12} sm={12} md={12}></GridItem> */}

        <GridItem xs={12} sm={12} md={12}>
          <Typography
            variant="h6"
            component="h2"
            style={{ marginBottom: "40px", float: "left", color: "#5a00f5" }}
          >
            Admin Revenue Details
          </Typography>
          <Card>
            <CardHeader id="chart">
              <ApexCharts
                options={options}
                series={series}
                type="bar"
                height={350}
              />
            </CardHeader>
            <div id="html-dist"></div>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={6}>
          <Typography
            variant="h6"
            component="h2"
            style={{ marginBottom: "40px", float: "left", color: "#5a00f5" }}
          >
            Top Collection Details
          </Typography>
          <Card>
            <CardHeader id="chart">
              <ApexCharts
                options={options1}
                series={series1}
                type="area"
                height={350}
              />
            </CardHeader>
            <div id="html-dist"></div>
          </Card>
        </GridItem> */}
      </GridContainer>

      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={12}></GridItem> */}

        <GridItem xs={12} sm={6} md={6}>
          <Typography
            variant="h6"
            component="h2"
            style={{ marginBottom: "40px", float: "left", color: "#5a00f5" }}
          >
            Top Selling Collection (BNB)
          </Typography>
          <Card>
            <CardHeader>
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={topCollBnb}
                  columns={columns2}
                  pageSize={5}
                  // checkboxSelection
                  // disableSelectionOnClick
                />
              </div>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Typography
            variant="h6"
            component="h2"
            style={{ marginBottom: "40px", float: "left", color: "#5a00f5" }}
          >
            Top Selling Collection (WBNB)
          </Typography>
          <Card>
            <CardHeader>
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={topCollWbnb}
                  columns={columns3}
                  pageSize={5}
                  // checkboxSelection
                  // disableSelectionOnClick
                />
              </div>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default DashboardCount;
