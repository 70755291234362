// import lib
import isEmpty from "./isEmpty";

export const firstLetterCase = (value) => {
  console.log(value);
  if (!isEmpty(value)) {
    return value.charAt(0).toUpperCase();
  }
  return "";
};
