import axios from "axios";

// import action
import { decodeJwt } from "./jsonWebToken";

// import lib
import config from "../lib/config";
import setAuthToken from "../lib/setAuthToken";

import { SET_CURRENT_USER } from "../constant";

// import constant
// import {
//     SET_CURRENT_USER
// } from '../constant';

export const getAllrequest = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/whitelistrequest`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const updateprivacy = async (Payload) => {
  try {
    let Resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/updateprivacy`,
      data: Payload
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const getprivacy = async () => {
  try {
    let Resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/getprivacy`
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const updateterms = async (Payload) => {
  try {
    let Resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/updateterms`,
      data: Payload
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const getterms = async () => {
  try {
    let Resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/getterms`
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const updateabout = async (Payload) => {
  try {
    let Resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/updateabout`,
      data: Payload
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const getabout = async () => {
  try {
    let Resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/getabout`
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const collectorlist = async (Payload) => {
  try {
    let Resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/collectorlist`,
      data: Payload
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const cmsupdate = async (Payload) => {
  try {
    let Resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/cmsupdate`,
      data: Payload
    });
    return Resp;
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const getsinglecms = async (iid) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getsinglecms/` + iid
    });
    return {
      status: "success",
      loading: false,
      result: respData
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response
    };
  }
};

export const getfees = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/token/getfee`
    });
    return {
      status: "success",
      loading: false,
      result: respData
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response
    };
  }
};

export const getcmsdetails = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/getcmsdetails`,
      headers: {
        Authorization: localStorage.admin_token
      }
    });
    return {
      loading: true,
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const enablewhitelist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/enablewhitelist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const updatefeeSetting = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/updatefeeSetting`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const approvewhitelist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/approvewhitelist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const getAllwhitelist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/getAllwhitelist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const rejectwhitelist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/rejectwhitelist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const checkwhitelist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/checkwhitelist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const addwhitelist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/addwhitelist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const updateblockCms = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/updateblockCms`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const login = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/login`,
      data
    });
    if (respData && respData.data && respData.data.token) {
      console.log(respData, "respppppppp");
      localStorage.setItem("admin_token", respData.data.token);
      setAuthToken(respData.data.token);
      decodeJwt(respData.data.token, dispatch);
      return {
        loading: false,
        result: respData.data.result
      };
    } else {
      return {
        loading: false,
        tfas: respData.data.tfa
      };
    }
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getblockcms = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/token/getblockcms`,
      headers: {
        Authorization: localStorage.admin_token
      }
    });

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getAllPairs = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getPairs`,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

// Update Currency Id
// export const updateCurrency = async (data,headers) => {
//     try {
//         let bodyFormData = new FormData();
//         bodyFormData.append('currencyName', data.currencyName);
//         bodyFormData.append('currencySymbol', data.currencySymbol);
//         bodyFormData.append('currencyType', data.currencyType);
//         bodyFormData.append('photo', data.photo);
//         bodyFormData.append('decimals', data.decimals);
//         bodyFormData.append('fee', data.fee);
//         bodyFormData.append('minimum', data.minimum);
//         bodyFormData.append('contractAddress',data.contractAddress);
//         bodyFormData.append('userId', data.userId);
//         var headersDefault = {
//             'Content-Type': 'multipart/form-data',
//             'Authorization': localStorage.admin_token
//         };
//         var finalHeaders = Object.assign(headersDefault, headers);

//         let respData = await axios({
//             'method': 'post',
//             'url': `${config.API_URL}/adminapi/updateCurrency`,
//             headers: finalHeaders,
//             data: bodyFormData
//         });

//     /* Merge defaults and options, without modifying defaults */
//         return {
//             loading: true,
//             result: respData.data.result
//         }

//     }
//     catch (err) {
//         return {
//             loading: false,
//             error: err
//         }
//     }
// }

export const addFaq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/addFaq`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.result
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const updateFaq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/updateFaq`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data.result
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};
export const getBidslist = async (filterData, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getBidslist`,
      params: filterData
    });
    return {
      loading: false,
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err?.response?.data?.errors
    };
  }
};

export const gettokenlistburn = async (filterData, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/gettokenlistburn`,
      params: filterData
    });
    return {
      loading: false,
      result: respData.data.tokenData
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const getuserreportlist = async (filterData, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getuserreportlist`,
      params: filterData
    });
    return {
      loading: false,
      result: respData.data.tokenData
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const getreportlist = async (filterData, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getreportlist`,
      params: filterData
    });
    return {
      loading: false,
      result: respData.data.tokenData
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};
export const getadmintokenlist = async (filterData, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getadmintokenlist`,
      params: filterData
    });
    return {
      loading: false,
      result: respData.data.tokenData
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const gettokenlist = async (filterData, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/gettokenlist`,
      params: filterData
    });
    return {
      loading: false,
      result: respData.data.tokenData
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const updatetokenlist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/updatetokenlist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      userValue: respData.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getcatory = async (filterData, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getcatory`,
      params: filterData
    });
    return {
      loading: false,
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const gettokendata = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/gettokendata/` + id
    });

    return {
      loading: false,
      result: respData.data.tokenData
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const deleteToken = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/deleteToken/` + id,
      headers: {
        Authorization: localStorage.admin_token
      }
    });
    return {
      loading: false,
      userValue: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getAllverifylist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/getAllverifylist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const rejectreq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/rejectreq`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const reportBlockByAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/reportBlockByAdmin`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
export const reportBlockUserAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/reportBlockUserAdmin`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
export const reportBlockCollAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/reportBlockCollAdmin`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const acceptreq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/acceptreq`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getAllprohibited = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/prohibitedlist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const addProhibited = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/addprohibited`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const addbanner = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/addbanner`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const removebanner = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/removebanner`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getbannercollection = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/getbannercollection`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getsingleword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/singleprohibited`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData.data.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const updateWord = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/updateWord`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData.data.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const deleteWord = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/deleteword`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getAllcategory = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/categorylist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const ipfsImageHashGet = async (payload) => {
  var formData = new FormData();
  if (payload.Image) {
    formData.append("Image", payload.Image);
  }
  if (payload.TokenName) {
    formData.append("name", payload.TokenName);
  }
  if (payload.tokenid) {
    formData.append("tokenid", payload.tokenid);
  }

  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/ipfsImageHashGet`,
      data: formData
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const ipfsmetadatafunciton = async (payload) => {
  var formData = new FormData();
  // if(payload.Image) { formData.append('Image', payload.Image); }
  if (payload.name) {
    formData.append("name", payload.name);
  }
  if (payload.image) {
    formData.append("image", payload.image);
  }
  if (payload.tokenId) {
    formData.append("tokenId", payload.tokenId);
  }
  // if(payload.description) { formData.append('description', payload.description); }

  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/ipfsmetadata`,
      data: formData
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const addapprove = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/addapprove`,

      data: data
    });

    return {
      loading: false,
      data: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const TokenAddOwnerAction = async (payload) => {
  try {
    var SendData = {};
    if (payload.Count) {
      SendData.Count = payload.Count;
    }
    if (payload.Price) {
      SendData.Price = payload.Price;
    }
    if (payload.TokenPrice) {
      SendData.TokenPrice = payload.TokenPrice;
    }
    if (payload.Owner) {
      SendData.Owner = payload.Owner;
    }
    if (payload.Balance) {
      SendData.Balance = payload.Balance;
    }
    if (payload.Quantity) {
      SendData.Quantity = payload.Quantity;
    }
    if (payload.ContractAddress) {
      SendData.ContractAddress = payload.ContractAddress;
    }
    if (payload.Type) {
      SendData.Type = payload.Type;
    }
    if (payload.HashValue) {
      SendData.HashValue = payload.HashValue;
    }
    if (payload.biddingtoken) {
      SendData.biddingtoken = payload.biddingtoken;
    }
    if (payload.Status) {
      SendData.Status = payload.Status;
    }
    if (payload.currencyName) {
      SendData.currencyName = payload.currencyName;
    }

    let resp1Data = await axios({
      method: "post",
      // url: `${config.vUrl}/token/add/owner`,
      url: `${config.baseUrl}/v2/routesAdmin/addOwner`,
      data: SendData
    });
    return { data: resp1Data.data };
  } catch (err) {
    return {
      //errors: err.response.data
    };
  }
};

export const TokenAddItemAction = async (payload) => {
  try {
    var formData = new FormData();
    if (payload.Image) {
      formData.append("Image", payload.Image);
    }
    if (payload.ipfsimage) {
      formData.append("ipfshash", payload.ipfsimage);
    }
    if (payload.Name) {
      formData.append("Name", payload.Name);
    }
    if (payload.Count) {
      formData.append("Count", payload.Count);
    }
    if (payload.Description) {
      formData.append("Description", payload.Description);
    }
    if (payload.Price) {
      formData.append("Price", payload.Price);
    }
    if (payload.MaximumBid) {
      formData.append("MaximumBid", payload.MaximumBid);
    }
    if (payload.TokenPrice) {
      formData.append("TokenPrice", payload.TokenPrice);
    }
    if (payload.Royalities) {
      formData.append("Royalities", payload.Royalities);
    }
    if (payload.Category_label) {
      formData.append("Category_label", payload.Category_label);
    }
    if (payload.Bid) {
      formData.append("Bid", payload.Bid);
    }
    if (payload.Properties) {
      formData.append("Properties", payload.Properties);
    }
    if (payload.Owner) {
      formData.append("Owner", payload.Owner);
    }
    if (payload.Creator) {
      formData.append("Creator", payload.Creator);
    }
    if (payload.CategoryId) {
      formData.append("CategoryId", payload.CategoryId);
    }
    if (payload.Quantity) {
      formData.append("Quantity", payload.Quantity);
    }
    if (payload.Balance) {
      formData.append("Balance", payload.Balance);
    }
    if (payload.ContractAddress) {
      formData.append("ContractAddress", payload.ContractAddress);
    }
    if (payload.Status) {
      formData.append("Status", payload.Status);
    }
    if (payload.HashValue) {
      formData.append("HashValue", payload.HashValue);
    }
    if (payload.Type) {
      formData.append("Type", payload.Type);
    }
    if (payload.MinimumBid) {
      formData.append("MinimumBid", payload.MinimumBid);
    }
    if (payload.EndClocktime) {
      formData.append("EndClocktime", payload.EndClocktime);
    }
    if (payload.Clocktime) {
      formData.append("Clocktime", payload.Clocktime);
    }
    // if (payload.UnLockcontent) {
    //   formData.append("UnLockcontent", payload.UnLockcontent);
    // }
    if (payload.PutOnSale) {
      formData.append("PutOnSale", payload.PutOnSale);
    }
    if (payload.PutOnSaleType) {
      formData.append("PutOnSaleType", payload.PutOnSaleType);
    }

    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/addItem`,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData
    });
    return { data: respData.data };
  } catch (err) {
    return { error: err };
  }
};

export const getAllCms = async (data) => {
  try {
    console.log(data, "cmslistttt");

    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/cmslist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getAllSetting = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/settinglist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const addcategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/addcategory`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const updatecategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/updatecategory`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getcategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/categorydetail`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getAllUsers = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/userListRequest`,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
//Get Currency id currency management module

export const getCurrencyId = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getCurrencyId?userid=` + data,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getFaqId = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getFaq/` + id,
      headers: {
        Authorization: localStorage.admin_token
      }
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
export const getPairId = async (data) => {
  try {
    console.log("data", data);
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getPairId?pairid=` + data,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/getUsers`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      data: respData
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const updateProfile = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/updateprofile`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};
export const updateUser = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/adminapi/updateUser`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("result of Data update Profile", respData);
    return {
      loading: false
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const updateEmail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/updateEmailTemplate`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("result of Data update Profile", respData);
    return {
      loading: false
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};
export const forgotPassword = async (data) => {
  try {
    console.log(data, "aaaaa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/forgotPassword`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
      data
    });

    return {
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
export const changePassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/resetPassword`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
      data
    });
    if (respData && respData.data && respData.data.tfa) {
      return {
        loading: false,
        tfas: respData.data.tfa
      };
    } else {
      return {
        loading: false
      };
    }
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const getLoginHistory = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getLoginHistory`,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getemaillist = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/getemaillist`,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
export const getFaqList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getFaqList`,
      headers: {
        Authorization: localStorage.admin_token
      },
      params: data
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getAddressProof = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/proof/getAllAddress`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

//newsLetter
export const getSedMails = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/user/sendnewsletter`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log(respData, "RespDa---------------------Data");
    return {
      result: respData
    };
  } catch (err) {
    console.log(err, "errNewsLetter");
  }
};
//NewsLetter

export const getEmailid = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/getemailid/` + id,
      headers: {
        Authorization: localStorage.admin_token
      }
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
export const getBankDetails = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/proof/getAllBank`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("result of Data", respData);

    return {
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
export const UpdateStatus = async (data) => {
  try {
    console.log("result of Data---", data);
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/adminapi/proof/updateStatus`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
      // data = {
      //     id:"check",
      //     status:3,
      //     role:1
      //     }
    });
    console.log("result of Data", respData);

    return {
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getadminUsers = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/adminprofile`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("aaaww", respData);

    return {
      loading: false,
      result: respData.data.data
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const get2faCode = async (data) => {
  console.log(data, "get2faCodeget2faCodeget2faCode");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/get2faCode`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });

    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
export const update2faCode = async (data) => {
  // console.log(data,'update2faCodeupdate2faCode');
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/update2faCode`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });

    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
//disable------------------------------------------------------
export const disable2faCode = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/diabled2faCode`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
export const enable2facode = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/enable2facode`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const deletecategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/deletecategory`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const deleteFaq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/deleteFaq`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const getcategorytype = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getcategorytype`
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      userValue: respData.data.contactData
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

//Support page
export const getSupportList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/supportList`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

//Using post method only Support page Pass id using delete api

export const deleteSupport = async (id) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/adminapi/supportDelete/` + id,
      headers: {
        Authorization: localStorage.admin_token
      }
    });
    console.log("result of Data", respData);
    console.log(id, "------------checkData");

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};
export const getSupportId = async (id, dispatch) => {
  console.log("id", id);

  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/getSupportId/` + id,
      headers: {
        Authorization: localStorage.admin_token
      }
    });
    return {
      loading: false,
      userValue: respData.data.userValue
    };
  } catch (err) {
    console.log(err, "err");
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};
export const closeticket = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/adminapi/closeticket/` + id,
      headers: {
        Authorization: localStorage.admin_token
      }
    });
    return {
      loading: false,
      userValue: respData.data.userValue
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const savereply = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/adminapi/savereply`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });

    // console.log(respData.data.result,"hsdjfhjksdhfjkdhfjhdsjkghdfjkghjkdfhgjkdfhgjkdfhgjkdhfgjkhdfk");

    return {
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const logout = (history) => {
  localStorage.removeItem("admin_token");
  history.push("/Login");
  setAuthToken("");
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const getSetting = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/setting`,
      headers: {
        Authorization: localStorage.admin_token
      }
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const updateSetting = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/adminapi/setting`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      status: "success",
      loading: false,
      messages: respData.data.messages,
      result: respData.data.result
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors
    };
  }
};

export const getproductdetails = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/adminapi/getproductdetails`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: false,
      result: respData
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const BurnField = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/token/BurnField`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const removevip = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/removevip`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const deleteuser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/deleteuser`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const addvip = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/addvip`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const removeFavour = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/removeFavour`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const addFavour = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/token/addFavour`,
      headers: {
        Authorization: localStorage.admin_token
      },
      data
    });
    return {
      loading: true,
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};
