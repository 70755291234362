import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { lighten, makeStyles } from "@material-ui/core/styles";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import Button from "components/CustomButtons/Button.js";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import clsx from "clsx";
import { toastAlert } from "lib/toastAlert";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGrid } from "@material-ui/data-grid";
import {
  acceptreq,
  deleteuser,
  getuserreportlist,
  rejectreq,
  reportBlockByAdmin,
  reportBlockCollAdmin,
  reportBlockUserAdmin
} from "./../../actions/users";

toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};
const rows = [];

const headCells = [
  { id: "Address", numeric: false, disablePadding: true, label: "Address" },
  { id: "Name", numeric: false, disablePadding: true, label: "Name" },
  { id: "E-mail", numeric: true, disablePadding: false, label: "E-mail" },
  { id: "Mobile", numeric: true, disablePadding: false, label: "Phone No" },
  { id: "accountType", numeric: true, disablePadding: false, label: "Action" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 76%"
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeterm: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
  const classes = useStyles();
  const customStyles = customStyle();
  const history = useHistory();
  const classesSearch = useToolbarStyles();
  const [data, setData] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userDetails, setUser] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [responsive, setresponsive] = useState(true);
  
  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  // const columns = [
  //   { field: "id", headerName: "ID", width: 90 },
  //   {
  //     field: "type",
  //     headerName: "Report Type",
  //     width: 170,
  //     renderCell: (params) => (
  //       <>
  //         <div>
  //           {console.log(params.row.type, "params")}
  //           {params.row.type === "user"
  //             ? "User"
  //             : params.row.type === "collection"
  //             ? "Collection"
  //             : "NFT"}
  //         </div>
  //       </>
  //     )
  //   },
  //   {
  //     field: "reportuser",
  //     headerName: "Report User",
  //     width: 170,
  //     valueGetter: (params) => halfAddrShow(params.row.reportuser)
  //   },
  //   {
  //     field: "spanuser",
  //     headerName: "Profile Address",
  //     width: 180,
  //     valueGetter: (params) => halfAddrShow(params.row.spanuser)
  //   },
  //   {
  //     field: "tokenName",
  //     headerName: "Collection Name",
  //     width: 180
  //     // valueGetter: (params) => halfAddrShow(params.row.spanuser)
  //   },
  //   {
  //     field: "tokenCounts",
  //     headerName: "NFT ID",
  //     width: 150
  //     // valueGetter: (params) => halfAddrShow(params.row.tokenCounts)
  //   },
  //   {
  //     field: "description",
  //     headerName: "Report Content",
  //     width: 190,
  //     renderCell: (params) => (
  //       <>
  //         {/* <div> */}
  //         <strong style={{ textDecoration: "underline" }}>
  //           {params.row.reportcategory} 
  //         </strong>
  //         <span>{params.row.description}</span>
  //         {/* </div> */}
  //       </>
  //     )
  //   }
  // ];

  const columns = [
    {
      key: "type",
      text: "Report Type",
      className: "reportuser",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          <p>
            {record.type == "user"
              ? "User"
              : record.type == "collection"
              ? "Collection"
              : "NFT"}
          </p>
        </div>
      )
    },
    {
      key: "reportuser",
      text: "Report User",
      className: "reportuser",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          <p>{halfAddrShow(record.spanuser)}</p>
        </div>
      )
    },
    {
      key: "spanuser",
      text: "Profile Address",
      className: "spanuser",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          <p>{halfAddrShow(record.reportuser)}</p>
        </div>
      )
    },
    {
      key: "tokenname",
      text: "Collection Name",
      className: "description",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          <p>{record.tokenName}</p>
        </div>
      )
    },
    {
      key: "tokenCounts",
      text: "Token ID",
      className: "description",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          <p>{record.tokenCounts}</p>
        </div>
      )
    },
    {
      key: "description",
      text: "Details",
      className: "description",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          <strong style={{ textDecoration: "underline" }}>
            {record.reportcategory}
          </strong>
          <p>{record.description}</p>
        </div>
      )
    },
    {
      key: "date",
      text: "Date",
      className: "Date",
      align: "left",
      sortable: false,
      cell: (record) => (
        // console.log(record.date,"date")
        <div>
          <p>{moment(record.date).format("lll")}</p>
        </div>
      )
    },
    {
      key: "action",
      text: "Action",
      className: "Date",
      align: "left",
      sortable: false,
      cell: (record) => (
        <div>
          {!record.blockStatus ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleReportBlock(record, "1")}
            >
              Block
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleReportBlock(record, "2")}
            >
              Un Block
            </Button>
          )}
        </div>
      )
    }
  ];

  const configdata = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last"
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
    defaultSortAsc: true
  };
  function pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }
  async function Accept(id) {
    var userid = {
      id: id
    };
    const res = await acceptreq(userid);
    toast.success("Accepted successfully", toasterOption);
    setTimeout(() => window.location.reload(false), 1000);
  }
  async function Reject(id) {
    var userid = {
      id: id
    };
    const { error } = await rejectreq(userid);
    toast.success("Rejected successfully", toasterOption);
    setTimeout(() => window.location.reload(false), 1000);
  }

  function editR(id) {
    if (id != "") {
      history.push("/useredit/" + id);
    }
  }
  async function deleteR(id) {
    var id = {
      Id: id
    };
    var res = await deleteuser(id);
    toast.success("User Details Deleted Successfully", toasterOption);
    getAll();
  }
  function lock() {}
  const handleChange = (event) => {
    const { name, value } = event.target;
    const self = this;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setSearchQuery(value);
    setTypingTimeout(
      setTimeout(function () {
        getAll();
      }, 1000)
    );
  };

  const handleRequestSort = (event, property) => {};

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  useEffect(() => {
    getAll();
  }, []);
  const getAll = async (search = {}) => {
    var res = await getuserreportlist();
    if (res && res.result.length > 0) {
      await res?.result.map((ele, i) => {
        ele.id = i + 1;
      });
      setUser(res.result);
    }
  };

  const handleReportBlock = async (reportData, flog) => {
    try {
      if (reportData.type == "user") {
        const payload = {
          addressUser: reportData.spanuser,
          id: reportData._id,
          flog: flog
        };
        const respD = await reportBlockUserAdmin(payload);
        console.log(respD, "respD");
        if (respD?.result?.data?.status) {
          toastAlert("success", respD?.result?.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else if (reportData.type == "collection") {
        const payload = {
          collName: reportData.tokenName,
          id: reportData._id,
          flog: flog
        };
        const respD = await reportBlockCollAdmin(payload);
        console.log(respD, "respD");
        if (respD?.result?.data?.status) {
          toastAlert("success", respD?.result?.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        if (flog == "2") {
          const payload = {
            tokenCounts: reportData.tokenCounts,
            id: reportData._id,
            flog: "2"
          };
          const respData = await reportBlockByAdmin(payload);
          console.log(respData, "respData");
          if (respData?.result?.data?.status) {
            toastAlert("success", respData?.result?.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } else {
          const payload = {
            tokenCounts: reportData.tokenCounts,
            id: reportData._id,
            flog: "1"
          };
          const respData = await reportBlockByAdmin(payload);
          console.log(respData, "respData");
          if (respData?.result?.data?.status) {
            toastAlert("success", respData?.result?.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar
          className={clsx(classesSearch.root, {
            [classesSearch.highlight]: selected.length > 0
          })}
        >
          <Typography
            className={classesSearch.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Users Report Details
          </Typography>
        </Toolbar>
        <div className="table-responsive">
          <ReactDatatable
            responsive={responsive}
            config={configdata}
            records={userDetails}
            columns={columns}
            onPageChange={pageChange()}
          />
          {/* <div style={{ height: 400, width: "100%" }}>
            <DataGrid rows={userDetails} columns={columns} pageSize={5} />
          </div> */}
        </div>
      </Paper>
    </div>
  );
}
