import React, { useEffect, useState } from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Help } from "@material-ui/icons";
// OutlinedInput id="otpv" type="text"
// core components
// import Header from "components/Header/Header.js";
// import HeaderLinksInner from "components/Header/HeaderLinksInner.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import twoFactor from "node-2fa";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CardFooter from "components/Card/CardFooter.js";
import { Modal } from "react-bootstrap/";
import CustomInput from "components/CustomInput/CustomInput.js";
import { toastAlert } from "../../lib/toastAlert";
import { CopyToClipboard } from "react-copy-to-clipboard";

// import styles from "assets/jss/material-kit-react/views/home.js";

import { get2faCode, update2faCode, disable2faCode,enable2facode } from "../../actions/users";
import axios from "axios";
// import isEmpty from '../lib/isEmpty';

const dashboardRoutes = [];

const useStyles = makeStyles();

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#0075bc",
    color: "#fff",
    maxWidth: 230,
    fontSize: theme.typography.pxToRem(10),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const initialFormValue = {
  password: "",
  confirmPassword: "",
  otpv: "",
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export default function TFA(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [settings2fa, setSettings2fa] = useState({});
  const [qrimage, setqrimage] = useState("");
  const [tfacode, settfacode] = useState("");
  const [tfastatussetSettings2fasetbackcode] = useState(false);
  const [otp, setotp] = useState("");
  const [showResults, setShowResults] = React.useState(false);

  const { otpv } = formValue;

  const { ...rest } = props;

  const Get2Facode = async () => {
    let REST = await get2faCode();
    if (REST && REST.result && REST.result.data && REST.result.data.result) {
      console.log(REST.result.data.result,'REST.result.data.result');
      setSettings2fa(REST.result.data.result);
      toastAlert("success", REST.result.data.result, "2FA");
    }
  };
  useEffect(() => {
    Get2Facode();
  }, []);

  const updatekey = async () => {
    if (otp == "") {
      toastAlert("error", "Please enter the OTP", "address");
    } else {
      var data = {
        code: otp,
        secret: settings2fa.secret,
        uri: settings2fa.imageUrl,
      };
      console.log(data,'dtdtdtdtdt');
      let result = await update2faCode(data);
      console.log(result,'resul');
      if (
        result &&
        result.result &&
        result.result.data &&
        result.result.data.success == true
      ) {
        // toastAlert("success", result.result.data.message, "2FA");
        toast.success("2FA Enabled",toasterOption);
        document.getElementById('otp').value = ""
        setFormValue(initialFormValue);
        Get2Facode();
        // otp = ""
      } else {
        toast.error("Invalid OTP",toasterOption);
        // toastAlert("error", result.result.data.error.code, "2FA");
      }
    }
  };
  //my
  const enable2fa = async () => {
    if (otp == "") {
      toastAlert("error", "Please enter the OTP", "address");
    } else {
      var data = {
        code: otp,
        secret: settings2fa.secret,
        uri: settings2fa.imageUrl,
      };
      console.log(data,'dtdtdtdtdtenabkle');
      let result = await enable2facode(data);
      console.log(result,'resulenable');
      if (
        result &&
        result.result &&
        result.result.data &&
        result.result.data.success == true
      ) {
        toastAlert("success", result.result.data.message, "2FA");
        toast.success("2FA Enabled",toasterOption);
        document.getElementById('otp').value = ""
        setFormValue(initialFormValue);
        Get2Facode(); 
        setotp("")      
      } else {
        toast.error("Invalid OTP",toasterOption);
        // toastAlert("error", result.result.data.error.code, "2FA");
      }
    }
  }
  //my
  const disablekey = async () => {
    if (otp == "") {
      toast.error("Please enter the OTP");
    } else {
      var data = {
        code: otp,
        secret: settings2fa.secret,
        uri: settings2fa.uri,
      };
      let result = await disable2faCode(data);
      if (result && result.result && result.result.data && result.result.data.success == true){
        toastAlert("success", result.result.data.message, "2FA");
        toast.error("2FA Disabled",toasterOption);
        document.getElementById('otp').value = ""
        setFormValue(initialFormValue);
        Get2Facode() 
      }else{
        toast.error("Invalid Code");
        // toastAlert("error", result.result.data.error.code, "2FA");
      }
    }
  };
  
  const handleChangeotp = (e) => {
    setotp(e.target.value);
  };

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
  };

  return (
    <div>
      <div className="inner_pageheader">
        <div className={classes.container}>
          <div className="inner_content_panel">
            <GridContainer>
              <GridItem sm={12} md={12} lg={12}>
                <h2 class="inner_section_title">
                  Two Factor Authentication<span>{}</span>
                </h2>
                <GridContainer className="tfa_panel">
                  <GridItem sm={12} md={7} lg={7}>
                    <div className="tfa_panel_div">
                      <div>
                        <h2>Scan QR Code</h2>
                        <img
                          className="mb-4"
                          src={
                            settings2fa &&
                            settings2fa.imageUrl &&
                            settings2fa.imageUrl
                          }
                          alt="Logo"
                        />
                      </div>
                      <div className="ml-4">
                        <h2>Backup your secret key</h2>
                        <p className="secret_key">
                          {settings2fa &&
                            settings2fa.secret &&
                            settings2fa.secret}
                        </p>
                        <div className="text_center_mobile">
                          <CopyToClipboard
                            text={
                              settings2fa &&
                              settings2fa.secret &&
                              settings2fa.secret
                            }
                            onCopy={() => {
                              toastAlert("success", "Copied", "copy");
                            }}
                          >
                            <Button className="auth_btn">Copy Code</Button>
                            {/*<a href="#" class="textBlue">Copy</a>*/}
                          </CopyToClipboard>
                        </div>
                        <p className="text-dark">
                          Please remember your secret key (
                          {settings2fa &&
                            settings2fa.secret &&
                            settings2fa.secret}
                          ). If your secret key gets lost, stolen or erased, you
                          will need this key to get back into your google
                          authentication! Otherwise resetting your google two
                          factor authentication requires opening a support
                          ticket and take atleast 7 days to process.
                        </p>

                        {/* { settings2fa.upated == 0 &&  */}
                        <div>
                          <p className="text-dark">
                            Please Enter the code to Confirm the secret key or
                            else the secret key will be changed
                          </p>
                          <form className="auth_form form_bg_white">
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <FormControl
                                  className={clsx(
                                    classes.margin,
                                    classes.textField
                                  )}
                                  variant="outlined"
                                >
                                  <InputLabel htmlFor="otp">
                                    Enter OTP
                                  </InputLabel>
                                  <OutlinedInput
                                    id="otp"
                                    type="text"
                                    onChange={handleChangeotp}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={80}
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </form>

                          <div className="text_center_mobile">
                            {/* <Button className="auth_btn" onClick={updatekey}>Continue</Button> */}
                          </div>
                        </div>
                        {/* }   */}
                        {settings2fa &&
                          settings2fa.upated &&
                          settings2fa.upated == 1 && (
                            <p className="text-dark">
                              Your Secret key was successfully Updated
                            </p>
                          )}
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <CardFooter>
                {settings2fa &&
                  settings2fa.status &&
                  settings2fa.status == "Enabled" && (
                    <Button disabled={true} color="primary" onClick={disablekey}>
                      {/* Disable */}
                      Enabled
                    </Button>
                  )}
                {settings2fa &&
                  settings2fa.status &&
                  settings2fa.status == "Disabled" && (
                    <Button color="primary" onClick={updatekey}>
                      Enable {" "}
                    </Button>
                  )}
                {/* {settings2fa &&
                  settings2fa.status &&
                  settings2fa.flag == 0 && (
                    <Button disabled={settings2fa.status == "Disabled"} color="primary" onClick={enable2fa}>
                      Enable 2FA
                    </Button>
                  )}  */}
                   {settings2fa &&
                  settings2fa.status &&
                  settings2fa.flag == 1 && (
                    <Button color="primary" onClick={disablekey}>
                      {/* Disable */}
                      Disable 2FA
                    </Button>
                  )}
              </CardFooter>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
