// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money,
  History,
  Email,
  Help,
  LiveHelp,
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp,
  ArrowForwardIcon
} from "@material-ui/icons";

import AdjustIcon from "@material-ui/icons/Adjust";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import ReportIcon from "@material-ui/icons/Report";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import CropFreeIcon from "@material-ui/icons/CropFree";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ViewListIcon from "@material-ui/icons/ViewList";
import SettingsIcon from "@material-ui/icons/Settings";
import LockIcon from "@material-ui/icons/Lock";
import DetailsIcon from "@material-ui/icons/Details";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import InfoIcon from "@material-ui/icons/Info";
import CategoryIcon from "@material-ui/icons/Category";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import DraftsIcon from "@material-ui/icons/Drafts";
const dashboardRoutes = [
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common"
  },
  {
    id: "forgot",
    path: "/forgot",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common"
  },
  {
    id: "change-password",
    path: "/changepassword/:authToken",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common"
  },
  {
    id: "security",
    path: "/securityType",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common"
  },
  {
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common"
  },

  /* Dashboard */
  {
    id: "dashboard",
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    isSideMenu: true,
    isEdit: false,
    isRestricted: "single"
  },
  /*Admin  */
  {
    id: "admin",
    path: "/list",
    name: "Admin Management",
    icon: List,
    isSideMenu: true,
    isEdit: true,
    isRestricted: "multi",
    child: [
      {
        id: "add",
        path: "/add"
      },
      {
        id: "edit",
        path: "/edit/:userId"
      }
    ]
  },

  /* Dashboard */
  {
    path: "#",
    id: "dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Dashboard Lists",
        path: "/dashboard",
        icon: SpeakerNotesIcon
      }
    ]
  },

  {
    path: "#",
    id: "nfts",
    name: "NFT",
    icon: AttachFileIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Create NFT",
        path: "/nftCreate",
        icon: AttachFileIcon
      },
      // {
      //   name: "List NFT",
      //   path: "/nftList",
      //   icon: AttachFileIcon
      // }
    ]
  },

  /* User */

  {
    path: "#",
    id: "category",
    name: "Category Management",
    icon: CategoryIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Category Lists",
        path: "/categorylist",
        icon: IndeterminateCheckBoxIcon
      }
    ]
  },

  {
    path: "#",
    id: "Bid",
    name: "Bid ",
    icon: DetailsIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Bid Lists",
        path: "/bidlist",
        icon: Person
      }
    ]
  },

  {
    path: "#",
    id: "token",
    name: "Token Management",
    icon: ViewListIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Token Lists",
        path: "/TokenList",
        icon: AssessmentIcon
      },
      {
        name: "Report Lists",
        path: "/ReportList",
        icon: ReportIcon
      }
    ]
  },
  // {
  //   path: "#",
  //   id: "nft",
  //   name: "Admin NFT",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Token Lists",
  //       path: "/NFTTokenList",
  //       icon: Person,
  //     },
  //   ],
  // },

  // {
  //   path: "#",
  //   id: "burn",
  //   name: "Burn",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Burn",
  //       path: "/burn",
  //       icon: Person,
  //     },
  //   ],
  // },

  //whiteList----------------------------------
  // {
  //     path: "#",
  //     id: "Whitelist",
  //     name: "White List ",
  //     icon: AttachFileIcon,
  //     type: "multi",
  //     isEdit: false,
  //     isSideMenu: true,
  //     child: [{
  //             name: "List",
  //             id: "list",
  //             path: "/whitelist",
  //             icon: Person,
  //         },
  //         // {
  //         //   name: "Requst",
  //         //   id: "request",
  //         //   path: "/requestwhitelist",
  //         //   icon: Person,
  //         // },
  //     ],
  // },
  // {
  //     path: "#",
  //     id: "Profile Verification",
  //     name: "Profile Verification",
  //     icon: List,
  //     type: "multi",
  //     isEdit: false,
  //     isSideMenu: true,
  //     child: [
  //       {
  //         name: "Verification Request",
  //         id: "list",
  //         path: "/profileverification",
  //         icon: Person,
  //       },
  //     ],
  //   },

  {
    path: "#",
    id: "TFA",
    name: "2FA Authendication",
    icon: CropFreeIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "2FA",
        path: "/tfa",
        icon: EqualizerIcon
      }
    ]
  },
  {
    path: "#",
    id: "collection",
    name: "Banner Management",
    icon: ViewCarouselIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Collection Lists",
        path: "/CollectionList",
        icon: RemoveCircleIcon
      }
    ]
  },
  {
    path: "#",
    id: "emailTemplate",
    name: "Email Template",
    icon: MailOutlineIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Email Template Lists",
        path: "/emailTemplate",
        icon: DraftsIcon
      }
    ]
  },

  {
    path: "#",
    id: "News-Letter",
    name: "News Letter",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "News Letter",
        path: "/newsletter",
        icon: Person
      }
    ]
  },

  {
    path: "#",
    id: "cms",
    name: "CMS Management",
    icon: ControlPointIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Terms and Condition",
        path: "/termsandcondition",
        icon: IndeterminateCheckBoxIcon
      },
      {
        name: "Privacy",
        path: "/privacy",
        icon: LockIcon
      },
      {
        name: "About Section",
        path: "/about",
        icon: InfoIcon
      }
    ]
  },

  {
    path: "#",
    id: "settings",
    name: "Settings",
    icon: SettingsIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Settings",
        path: "/setting",
        icon: SettingsEthernetIcon
      }
    ]
  },

  {
    path: "#",
    id: "user",
    name: "User-profile Management",
    icon: AssignmentIndIcon,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "User-profile Lists",
        path: "/userlist",
        icon: FormatListBulletedIcon
      },
      {
        name: "User Report",
        path: "/reportuserlist",
        icon: ReportIcon
      }
    ]
  }
  //   {
  //     path: "#",
  //     id: "newsletter",
  //     name: "News Letter",
  //     icon: MailOutlineIcon,
  //     type: "multi",
  //     isEdit: false,
  //     isSideMenu: true,
  // },
];

export default dashboardRoutes;
