import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from 'react-phone-input-2'

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import config from '../../actions/config'

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';

import {  getUser, updateUser } from '../../actions/users';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
  'name': "",
  'firstName': "",
  'lastName': "",
  'email': "",
  'phonenumber': "",
  'phoneCode':"",


}

const useStyles = makeStyles(styles);

export default function UserProfile(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});

  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const { userId } = useParams();
   // console.log(userId,"asdfdsfdsfdsf");

//   const handleFile = (event) => {
//     const { id, files } = event.target;
//     //settmpupimagefront(URL.createObjectURL(event.target.files[0]));

//     let formData = { ...formValue, ...{ [id]: files[0] } }
//     setFormValue(formData)
//     //setValidateError(formData)
//   };


  // function
  const onChange = (e) => {
    e.preventDefault();
   // console.log(e.target);
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
    console.log(formValue);
    //setValidateError(formData)
  }

    const {
      
        email,
        phoneNo,
        phoneCode,
    
    } = formValue

    const handlePhoneNumber = (value, country) => {
      const { dialCode } = country;
      let phoneNo = value;
      let formData = formValue;
      if (dialCode) {
          formData = {
              ...formData, ...{
                  phoneCode: dialCode,
                  phoneNo: phoneNo.slice(dialCode.length),
              }
          }
      } else if (value) {
          formData = { ...formData, ...{ phoneNo } }
      }
      setFormValue(formData)
      // setValidateError(validation(formData))

  }
    const handleBlurPhone = (e) => {
      setToched({ ...toched, ...{ 'phoneNo': true, 'phoneCode': true } })
  }
  const handleFormSubmit = async (e) => {
    //console.log("saran");
    e.preventDefault();
    console.log(formValue);

    let reqData = {
        email,
        phoneNo,
        phoneCode,
        userId
    }
    console.log(reqData);
    let { error } = await updateUser(reqData);
    console.log(reqData);
    if (isEmpty(error)) {
      toast.success('User Updated', toasterOption);
       history.push('/userlist')
    } else {
      setValidateError(error);
    }
  }
  let formdata = {};
  const getUserData = async () => {
    var data = {
      "id":userId
    }
    var test = await getUser(data);
    if(test && test.data && test.data.data &&  test.data.data.data){
      setUser(test.data.data.data);
    }
  }

  useEffect(() => {
    //logout(history)
    getUserData();
  }, [])


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit} disabled>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Update Users</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>                 
                 <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Name"
                      id="Name"
                      value={user && user.name}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem> 
                
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Custom Url"
                      value={user && user.customurl && config.frontUrl+user.customurl}
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Personal Site or Portfolio"
                      value={user && user.personalsite}
                      id="personalsite"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>

                   <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Email address"
                      value={user && user.email}
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                 <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Bio"
                      value={user && user.bio}
                      id="bio"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                   <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Mobile"
                      value={user && user.mobile}
                      id="mobile"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                   <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Twitter"
                      value={user && user.twitter}
                      id="twitter"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                   <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="You Tube"
                      value={user && user.youtube}
                      id="youtube"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="FaceBook"
                      value={user && user.facebook}
                      id="facebook"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Instagram"
                      value={user && user.instagram}
                      id="instagram"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                  {user && user.image && user.image!=null && user.image!=undefined && user.image!="" &&
                    <img src={config.baseUrl+"/profile/"+user.image}/>
                  }
                  </GridItem>
                </GridContainer>
                
              </CardBody>
             {/* <CardFooter>
                <Button color="primary" type="submit">Update</Button>
              </CardFooter> */}
            </form>
          </Card>
        </GridItem>       
      </GridContainer>
    </div>
  );
}
