import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Modal from "@material-ui/core/Modal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toastAlert } from "lib/toastAlert";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Web3 from "web3";
import Multiple_ABI from "../../ABI/BEP1155.json";
import Single_ABI from "../../ABI/BEP721.json";
import {
  TokenAddItemAction,
  TokenAddOwnerAction,
  addapprove,
  getAllcategory,
  ipfsImageHashGet,
  ipfsmetadatafunciton
} from "../../actions/users";
import config from "../../lib/config";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  input: {
    display: "none"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 600
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
  //   root: {
  //     display: "flex",
  //     flexDirection: "column",
  //     alignItems: "center",
  //     "& > *": {
  //       margin: theme.spacing(1)
  //     }
  //   }
});

const useStyles = makeStyles(styles);

export default function NFTCretae() {
  var myDate = new Date();
  var newdat = myDate.setDate(myDate.getDate() + parseInt(1));
  var initialEnd = new Date(newdat);
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [Loading, setLoaing] = useState(false);
  const [openCal, setOpenCal] = React.useState(false);
  const [openCalExp, setOpenCalExp] = useState(false);
  const [state, setState] = React.useState();
  const [CollectiblesType, setCollectiblesType] = useState("Single");
  const [TokenFilePreReader, setTokenFilePreReader] = useState("");
  const [TokenFilePreUrl, setTokenFilePreUrl] = useState("");
  const [TokenFile, setTokenFile] = useState("");
  const [Price, setPrice] = useState(0);
  const [TokenName, setTokenName] = useState("");
  const [TokenDescription, setTokenDescription] = useState("");
  const [TokenRoyalities, setTokenRoyalities] = useState(5);
  const [UnLockcontent, Set_UnLockcontent] = useState("");
  const [TokenQuantity, Set_TokenQuantity] = useState(1);
  const [CategoryOption, setCategoryOption] = useState([]);
  const [TokenCategory, setTokenCategory] = useState();
  const [putonmarket, setputonmarket] = React.useState(false);
  const [PutOnSale, setPutOnSale] = useState(false);
  const [PutOnSaleType, setPutOnSaleType] = useState("putonmarket");
  const [MinimumBid, Set_MinimumBid] = useState(0);
  const [MaximumBid, Set_MaximumBid] = useState(0);
  const [StartDate, Set_StartDate] = useState("Right after listing");
  const [EndDate, Set_EndDate] = useState("1 Day");
  const [Clocktime, set_Clocktime] = useState(new Date());
  const [EndClocktime, set_EndClocktime] = useState(initialEnd);
  const [TokenPrice, setTokenPrice] = useState(0);
  console.log(Clocktime, "Clocktime");
  useEffect(() => {
    GetCategoryCall();
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    console.log(name, "name");
    const value = event.target.value;
    if (name == "Single") {
      setCollectiblesType("Single");
    } else if (name == "Multiple") {
      setCollectiblesType("Multiple");
    } else if (name == "TokenName") {
      setTokenName(value);
    } else if (name == "TokenDescription") {
      setTokenDescription(value);
    } else if (name == "TokenQuantity") {
      Set_TokenQuantity(value);
    } else if (name == "TokenRoyalities") {
      setTokenRoyalities(value);
    } else if (name == "category") {
      setTokenCategory(value);
    } else if (name == "Price") {
      setPrice(value);
    }
  };

  const changePutOnSaleType = (type) => {
    setPutOnSaleType(type);
    if (type == "FixedPrice") {
      Set_MinimumBid("");
    } else if ((type = "TimedAuction")) {
      // setTokenPrice("");
      setPrice(0);
    } else {
      Set_MinimumBid("");
      // setTokenPrice("");
      setPrice(0);
    }
  };

  const CheckedChange = (e) => {
    if (e && e.target && e.target.name) {
      if (e.target.name == "putonsale") {
        if (PutOnSale == false) {
          setputonmarket(true);
          setPutOnSale(true);
          if (PutOnSaleType == "" || PutOnSaleType == "putonmarket") {
            setPutOnSaleType("FixedPrice");
          }
          setPrice(0);
        } else {
          setputonmarket(false);
          setPutOnSale(false);
        }
      }
      // else if (e.target.name == "unlockoncepurchased") {
      //   if (Unlockoncepurchased == false) {
      //     Set_Unlockoncepurchased(true);
      //   } else {
      //     Set_Unlockoncepurchased(false);
      //   }
      // }
    }
  };

  const selectFileChange = (e) => {
    var validExtensions = [
      "jpg",
      "png",
      "gif",
      "webp",
      "mp4",
      "mp3",
      "jpeg",
      "svg",
      // "mpeg",
      // "avi",
      // "mav",
      "wav"
    ]; //array of valid extensions
    if (e.target && e.target.files) {
      var reader = new FileReader();
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
      //   if ($.inArray(fileNameExt, validExtensions) == -1) {
      //     toast.error(
      //       "Only these file types are accepted : " + validExtensions.join(", "),
      //       toasterOption
      //     );
      //     $("input[name='image']").val("");
      //     return false;
      //   } else {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 100) {
        toastAlert("error", "File size exceeds 100 MB");
        // $("input[name='image']").val("");
        return false;
      } else {
        setTokenFile(file);
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          if (reader.result) {
            setTokenFilePreReader(reader.result);
          }
        }.bind(this);
        setTokenFilePreUrl(e.target.value);
        toastAlert("success", "File Uploaded Successfully");
      }
      //   }
    }
  };

  async function GetCategoryCall() {
    let arrCat = [];
    var resp = await getAllcategory();
    console.log(resp, "ewrwerwrwer");
    for (const val of resp?.result?.data) {
      arrCat.push(val.category);
    }
    setCategoryOption(arrCat);
  }
  const inputChange = async (e) => {
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      var value = e.target.value;
      switch (e.target.name) {
        case "TokenName":
          setTokenName(value);
          break;
        case "TokenDescription":
          setTokenDescription(value);
          break;
        case "TokenRoyalities":
          setTokenRoyalities(value);
          break;
        // case "TokenProperties":
        //   setTokenProperties(value);
        //   break;
        // case "UnLockcontent":
        //   Set_UnLockcontent(value);
        //   break;
        case "MinimumBid":
          Set_MinimumBid(value);
          break;
        case "MaximumBid":
          Set_MaximumBid(value);
          break;
        case "TokenQuantity":
          Set_TokenQuantity(value);
          break;
        default:
      }
    }
  };

  async function DateChange(from, val) {
    if (from == "StartDateDropDown") {
      if (val == "PickStartDate") {
        ModalAction("calendar_modal", "show");
      } else {
        Set_StartDate("Right after listing");
      }
    } else if (from == "EndDateDropDown") {
      if (val == "PickEndDate") {
        ModalAction("calendar_modal_expire", "show");
      } else {
        Set_EndDate(val);
        var myDate = new Date();
        var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        var newdate = new Date(newdat);
        set_EndClocktime(newdate);
      }
    }
  }
  const handleClose1 = () => {
    setOpenCal(false);
  };
  const handleClose2 = () => {
    setOpenCalExp(false);
  };

  async function ModalAction(id, type) {
    // window.$("#" + id).modal(type);

    if (id == "calendar_modal") {
      setOpenCal(true);
      if (Clocktime) {
        var dt = new Date(Clocktime);
        dt.setSeconds(0);
        var dt1 = dt.toLocaleString();
        Set_StartDate(dt1);
      }
    } else if (id == "calendar_modal_expire") {
      setOpenCalExp(true);
      if (EndClocktime) {
        var dt = new Date(EndClocktime);
        dt.setSeconds(0);
        var dt1 = dt.toLocaleString();
        Set_EndDate(dt1);
      }
    }
  }

  const bodyCalender_Open = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Choose date</h2>
      <p id="simple-modal-description">
        <div className="pb-3">
          <Datetime
            minDate={new Date().getTime()}
            input={false}
            value={Clocktime}
            onChange={(value) => set_Clocktime(value)}
          />
        </div>
        <input
          type="submit"
          name="Done"
          value="Done"
          className="primary_btn"
          id="doneStartDate"
          onClick={() => setOpenCal(false)}
        />
      </p>
      {/* <SimpleModal /> */}
    </div>
  );
  const bodyCalender_Expire_Open = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Choose date</h2>
      <p id="simple-modal-description">
        <div className="pb-3">
          <Datetime
            input={false}
            value={EndClocktime}
            minDate={new Date().getTime()}
            onChange={(value) => set_EndClocktime(value)}
          />
        </div>
        <input
          type="submit"
          name="Done"
          value="Done"
          className="primary_btn"
          id="doneEndDate"
          onClick={() => setOpenCalExp(false)}
        />
      </p>
      {/* <SimpleModal /> */}
    </div>
  );

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }
  async function ChecktokenDecimal(amount, decimals) {
    var result = 0;
    var decimalsLength = 18;
    if (decimals && decimals > 0) {
      decimalsLength = decimals;
    }
    try {
      var number = amount.toString();
      var number1 = number.split(".");
      if (number1[1] && number1[1].length && number1[1].length > 0) {
        result = number1[0];
      } else {
        result = amount;
      }
      return result;
    } catch (err) {
      return result;
    }
  }

  const MintCall = async () => {
    setLoaing(true);
    let network = config.network;
    console.log(
      window.web3.currentProvider.networkVersion,
      "window.web3.currentProvider.networkVersion",
      network
    );
    if (window.web3.currentProvider.networkVersion == network) {
      let web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      web3 = new Web3(window.web3.currentProvider);
      var currAddr = await web3.eth.getAccounts();
      console.log(currAddr, "currAddr");
      if (!currAddr) {
        toastAlert("error", "OOPS!..connect Your Wallet");
        setLoaing(false);
        return false;
      }
      if (config.adminAddr !== currAddr[0]) {
        toastAlert("error", "Please connect admin address");
        setLoaing(false);
        return false;
      }
      console.log(TokenFile, "TokenFile");
      if (TokenFile == "") {
        toastAlert("error", "Choose image file");
        setLoaing(false);
        return false;
      } else if (TokenName == "") {
        toastAlert("error", "Enter NFT Name");
        setLoaing(false);
        return false;
      } else if (TokenRoyalities == "" || isNaN(TokenRoyalities)) {
        toastAlert("error", "Enter NFT Royalty Valid");
        setLoaing(false);
        return false;
      } else if (TokenCategory == "" || TokenCategory == undefined) {
        toastAlert("error", "Select NFT Category");
        setLoaing(false);
        return false;
      }
      if (PutOnSaleType == "FixedPrice" && putonmarket) {
        if (Price == "" || Price == undefined || isNaN(Price)) {
          toastAlert("error", "Enter NFT Price Valid");
          setLoaing(false);
          return false;
        }
      }
      if (PutOnSaleType == "TimedAuction" && putonmarket) {
        const regex = new RegExp(`^\\d*\\.?\\d{0,${8}}$`);

        if (parseFloat(MinimumBid) <= 0) {
          toastAlert("error", "Enter Valid Minimum Bid");
          setLoaing(false);
          return false;
        }
        if (parseFloat(MaximumBid) <= 0) {
          toastAlert("error", "Enter Valid Maximum Bid");
          setLoaing(false);
          return false;
        }
        if (parseFloat(MaximumBid) < parseFloat(MinimumBid)) {
          toastAlert("error", "Maximum Bid must be Greater than Minimum Bid");
          setLoaing(false);
          return false;
        }
        if (MinimumBid == "") {
          toastAlert("error", "Minimum bid amount is required");
          setLoaing(false);
          return false;
        }
        if (MaximumBid == "") {
          toastAlert("error", "Maximum bid amount is required");
          setLoaing(false);
          return false;
        }
        if (!regex.test(MinimumBid)) {
          toastAlert("error", "Decimal value must be less than or equal to 8");
          setLoaing(false);
          return false;
        }
        if (!regex.test(MaximumBid)) {
          toastAlert("error", "Decimal value must be less than or equal to 8");
          setLoaing(false);
          return false;
        }

        var currDate = new Date();
        var sDate = new Date(Clocktime);
        if (StartDate == "Right after listing") {
          set_Clocktime(currDate);
          sDate = currDate;
        }
        var startDate = new Date(Clocktime);
        var eDate = new Date(EndClocktime);
        startDate.setSeconds(0);
        eDate.setSeconds(0);
        if (new Date(currDate) > new Date(sDate)) {
          toastAlert("error", "Start date & Time must be greater than now");
          setLoaing(false);
          return false;
        }
        if (Date.parse(startDate) >= Date.parse(eDate)) {
          toastAlert(
            "error",
            "End date & Time must be greater than start date and Time"
          );
          setLoaing(false);
          return false;
        }
      }
      const singleContract = new web3.eth.Contract(
        Single_ABI,
        config.singlesmartContract
      );

      const multipleContract = new web3.eth.Contract(
        Multiple_ABI,
        config.multiplesmartContract
      );

      let selectedcontract =
        CollectiblesType == "Single"
          ? config.singlesmartContract
          : config.multiplesmartContract;
      let CoursetroContract =
        CollectiblesType == "Single" ? singleContract : multipleContract;
      console.log(CoursetroContract, "CoursetroContract", selectedcontract);
      console.log(Clocktime, "Enddd", EndClocktime, PutOnSaleType);
      await CoursetroContract.methods
        .setApproval(selectedcontract, true)
        .send({
          from: currAddr[0].toLowerCase()
        })
        .then(async (result) => {
          let datass = {
            address: currAddr[0].toLowerCase(),
            contractaddress: selectedcontract
          };
          let restu = await addapprove(datass);
          if (restu) {
            toastAlert("success", "Wallet authorized");
            let unix = Math.round(new Date() / 1000);
            let ran = Math.floor(Math.random() * 100 + 1);
            let tokenid = unix + ran;
            let senddata = {
              Image: TokenFile,
              tokenid: tokenid,
              TokenName: TokenName
            };
            console.log(senddata, "senddata");
            let ipfsimghashget = await ipfsImageHashGet(senddata);
            console.log(ipfsimghashget, "Ipfsget");
            if (ipfsimghashget.data !== undefined) {
              if (ipfsimghashget.data.ipfsval != "") {
                let ipfsHash = ipfsimghashget.data.ipfsval;
                var newobj = {
                  name: TokenName,
                  tokenId: tokenid,
                  image: config.IPFS_IMG + "/" + ipfsimghashget.data.ipfsval
                };
                var ipfsmetatagVal = await ipfsmetadatafunciton(newobj);
                console.log(ipfsmetatagVal, "wadsssss");
                if (ipfsmetatagVal.data !== undefined) {
                  if (ipfsmetatagVal.data.ipfsval != "") {
                    var ipfsurl = ipfsmetatagVal.data.ipfsval;
                    ipfsurl = `${config.IPFS_IMG}/${ipfsurl}`;
                    const Digits = 1e18;
                    let TokenPriceInStr = (Price * Digits).toString();
                    TokenPriceInStr = await convert(TokenPriceInStr);
                    TokenPriceInStr = await ChecktokenDecimal(
                      TokenPriceInStr,
                      Digits
                    );
                    if (CollectiblesType == "Single") {
                      await CoursetroContract.methods
                        .mint(
                          tokenid,
                          TokenName,
                          ipfsurl,
                          ipfsHash,
                          TokenPriceInStr,
                          TokenQuantity,
                          TokenRoyalities
                        )
                        .send({ from: currAddr[0].toLowerCase() })
                        .then(async (result) => {
                          let transactionHash = "";
                          if (result.transactionHash) {
                            transactionHash = result.transactionHash;
                          } else {
                            transactionHash = result;
                          }
                          let TokenAddItemPayload = {
                            Image: TokenFile,
                            ipfsimage: ipfsHash,
                            Name: TokenName,
                            Count: tokenid,
                            Description: TokenDescription,
                            Price: Price,
                            Royalities: TokenRoyalities,
                            Category_label: TokenCategory,
                            CategoryId: TokenCategory,
                            Owner: currAddr[0].toLowerCase(),
                            Creator: currAddr[0].toLowerCase(),
                            Quantity: TokenQuantity,
                            Balance: TokenQuantity,
                            ContractAddress: selectedcontract,
                            Status: true,
                            HashValue: transactionHash,
                            Type: 721,
                            MinimumBid: 0,
                            MaximumBid: 0,
                            Clocktime: "",
                            EndClocktime: "",
                            UnLockcontent: "",
                            PutOnSale: PutOnSale,
                            PutOnSaleType: PutOnSaleType
                          };
                          if (!putonmarket) {
                            TokenAddItemPayload.Price = 0;
                            TokenAddItemPayload.TokenPrice = 0;
                            TokenAddItemPayload.MinimumBid = 0;
                            TokenAddItemPayload.MaximumBid = 0;
                            TokenAddItemPayload.Clocktime = Clocktime;
                            TokenAddItemPayload.EndClocktime = EndClocktime;
                            TokenAddItemPayload.PutOnSale = false;
                            TokenAddItemPayload.PutOnSaleType = "putonmarket";
                          } else if (PutOnSaleType == "FixedPrice") {
                            TokenAddItemPayload.Price = Price;
                          } else if (PutOnSaleType == "TimedAuction") {
                            TokenAddItemPayload.MinimumBid = MinimumBid;
                            TokenAddItemPayload.MaximumBid = MaximumBid;
                            TokenAddItemPayload.Clocktime = Clocktime;
                            TokenAddItemPayload.EndClocktime = EndClocktime;
                            TokenAddItemPayload.Price = 0;
                          } else {
                            TokenAddItemPayload.PutOnSaleType = PutOnSaleType;
                            TokenAddItemPayload.MinimumBid = MinimumBid;
                            TokenAddItemPayload.MaximumBid = MaximumBid;
                          }
                          await TokenAddItemAction(TokenAddItemPayload);

                          let TokenAddOwnerPayload = {
                            Count: tokenid,
                            Price: Price,
                            Owner: currAddr[0].toLowerCase(),
                            Balance: TokenQuantity,
                            Quantity: TokenQuantity,
                            ContractAddress: selectedcontract,
                            Type: 721,
                            HashValue: transactionHash,
                            Status: true
                          };
                          if (!putonmarket) {
                            TokenAddOwnerPayload.Price = 0;
                            TokenAddOwnerPayload.TokenPrice = 0;
                            TokenAddItemPayload.MinimumBid = 0;
                            TokenAddItemPayload.MaximumBid = 0;
                          } else if (PutOnSaleType == "FixedPrice") {
                            TokenAddOwnerPayload.Price = Price;
                          } else if (PutOnSaleType == "TimedAuction") {
                            TokenAddItemPayload.MinimumBid = MinimumBid;
                            TokenAddItemPayload.MaximumBid = MaximumBid;
                          }
                          await TokenAddOwnerAction(TokenAddOwnerPayload);
                          toastAlert("success", "NFT minted done");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        });
                    } else {
                      await CoursetroContract.methods
                        .mint(
                          tokenid,
                          TokenPriceInStr,
                          TokenRoyalities,
                          TokenQuantity,
                          TokenName,
                          ipfsHash,
                          ipfsurl
                        )
                        .send({ from: currAddr[0].toLowerCase() })
                        .then(async (result) => {
                          var transactionHash = "";
                          if (result.transactionHash) {
                            transactionHash = result.transactionHash;
                          } else {
                            transactionHash = result;
                          }
                          toastAlert("error", "NFT minted successfully");
                          let TokenAddItemPayload = {
                            Image: TokenFile,
                            ipfsimage: ipfsHash,
                            Name: TokenName,
                            Count: tokenid,
                            Description: TokenDescription,
                            Price: Price,
                            TokenPrice: TokenPrice,
                            Royalities: TokenRoyalities,
                            Category_label: TokenCategory,
                            // Properties: JSON.stringify(values.val),
                            Owner: currAddr[0].toLowerCase(),
                            Creator: currAddr[0].toLowerCase(),
                            CategoryId: TokenCategory,
                            Quantity: TokenQuantity,
                            Balance: TokenQuantity,
                            ContractAddress: selectedcontract,
                            Status: true,
                            HashValue: transactionHash,
                            Type: 1155,
                            MinimumBid: 0,
                            MaximumBid: 0,
                            Clocktime: "",
                            EndClocktime: "",
                            UnLockcontent: "",
                            PutOnSale: PutOnSale,
                            PutOnSaleType: PutOnSaleType
                            // currencyName: currencyName
                          };
                          if (!putonmarket) {
                            TokenAddItemPayload.Price = 0;
                            TokenAddItemPayload.TokenPrice = 0;
                            TokenAddItemPayload.MinimumBid = 0;
                            TokenAddItemPayload.MaximumBid = 0;
                            TokenAddItemPayload.Clocktime = Clocktime;
                            TokenAddItemPayload.EndClocktime = EndClocktime;
                            TokenAddItemPayload.PutOnSale = false;
                            TokenAddItemPayload.PutOnSaleType = "putonmarket";
                          } else if (PutOnSale == true) {
                            if (PutOnSaleType == "FixedPrice") {
                              TokenAddItemPayload.Price = Price;
                              TokenAddItemPayload.TokenPrice = TokenPrice;
                            } else if (PutOnSaleType == "TimedAuction") {
                              TokenAddItemPayload.MinimumBid = MinimumBid;
                              TokenAddItemPayload.MaximumBid = MaximumBid;
                              TokenAddItemPayload.Clocktime = Clocktime;
                              TokenAddItemPayload.EndClocktime = EndClocktime;
                              TokenAddItemPayload.Price = 0;
                            } else {
                              TokenAddItemPayload.MinimumBid = MinimumBid;
                              TokenAddItemPayload.MaximumBid = MaximumBid;
                            }
                          }
                          await TokenAddItemAction(TokenAddItemPayload);
                          let TokenAddOwnerPayload = {
                            Count: tokenid,
                            Price: Price,
                            TokenPrice: TokenPrice,
                            Owner: currAddr[0].toLowerCase(),
                            Balance: TokenQuantity,
                            Quantity: TokenQuantity,
                            ContractAddress: selectedcontract,
                            Type: 1155,
                            HashValue: transactionHash,
                            Status: true
                          };
                          if (!putonmarket) {
                            TokenAddOwnerPayload.Price = 0;
                            TokenAddOwnerPayload.TokenPrice = 0;
                            TokenAddItemPayload.MinimumBid = 0;
                            TokenAddItemPayload.MaximumBid = 0;
                          } else if (PutOnSaleType == "FixedPrice") {
                            TokenAddOwnerPayload.Price = Price;
                            TokenAddOwnerPayload.TokenPrice = TokenPrice;
                          }
                          await TokenAddOwnerAction(TokenAddOwnerPayload);
                          toastAlert("success", "NFT minted done");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          toastAlert("error", "Action rejected by user");
                          setLoaing(false);
                        });
                    }
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          toastAlert("error", "Wallet authorization failed");
          setLoaing(false);
        });
    } else {
      toastAlert("error", "Please choose binance network", "fee");
      setLoaing(false);
    }
  };
  return (
    <div>
      {" "}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              method="POST"
              className={classes.form}
              noValidate
              onSubmit={""}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Create NFT</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={6} sm={6} md={6}>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          defaultValue="start"
                        >
                          <FormControlLabel
                            checked={CollectiblesType === "Single"}
                            value="Single"
                            name="Single"
                            control={<Radio name="Single" color="primary" />}
                            label="Single"
                            labelPlacement="start"
                            onChange={handleChange}
                          />
                          <FormControlLabel
                            checked={CollectiblesType === "Multiple"}
                            value="Multiple"
                            name="Multiple"
                            control={<Radio color="primary" />}
                            label="Multiple"
                            labelPlacement="start"
                            onChange={handleChange}
                          />
                        </RadioGroup>
                        <div className={classes.root}>
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="upload_file"
                            multiple
                            type="file"
                            onChange={selectFileChange}
                          />
                          <label htmlFor="upload_file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                            >
                              Upload file (PNG, JPG, JPEG, SVG, GIF, WEBP, MP4,
                              WAV, MP3 . Max 100 MB)
                            </Button>
                          </label>
                        </div>
                        <GridContainer>
                          <GridItem xs={6} sm={6} md={6}>
                            {/* <FormHelperText>
                              {"Create Collection"}
                            </FormHelperText>

                            <ButtonGroup
                              color="primary"
                              aria-label="outlined primary button group"
                            >
                              <Button style={{ marginRight: "12px" }}>+</Button>
                              <Button>Default</Button>
                            </ButtonGroup> */}
                          </GridItem>
                          <GridItem xs={6} sm={6} md={6}>
                            {/* <FormControl
                              variant="filled"
                              className={classes.formControl}
                            >
                              <InputLabel htmlFor="filled-age-native-simple">
                                Collection
                              </InputLabel>
                              <Select
                                native
                                value={state}
                                onChange={handleChange}
                                inputProps={{
                                  name: "collection",
                                  id: "filled-age-native-simple"
                                }}
                                fullWidth
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl> */}
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <a href="#">
                          {TokenFilePreUrl.split(".").pop() == "mp4" ? (
                            <video
                              src={
                                TokenFilePreReader != ""
                                  ? TokenFilePreReader
                                  : `${config.baseUrl}images/previewThumb1.png`
                              }
                              type="video/mp4"
                              id="get_file_2"
                              class="lazy nft__item_preview"
                              alt=""
                              autoPlay
                              playsInLine
                              loop
                              muted
                            ></video>
                          ) : (
                            <div className="text-center">
                              {TokenFilePreUrl.split(".").pop() == "mp3" ||
                              TokenFilePreUrl.split(".").pop() == "wav" ? (
                                <div>
                                  <img
                                    src={config.baseUrl + "images/music.png"}
                                    id="get_file_2"
                                    class="lazy nft__item_preview"
                                    alt=""
                                  />
                                  <audio
                                    src={
                                      TokenFilePreReader != ""
                                        ? TokenFilePreReader
                                        : `${config.baseUrl}images/previewThumb1.png`
                                    }
                                    type="audio/mp3"
                                    controls
                                    controlsList="nodownload"
                                    className="audio home"
                                  ></audio>
                                </div>
                              ) : (
                                <img
                                  src={
                                    TokenFilePreReader != ""
                                      ? TokenFilePreReader
                                      : `${config.baseUrl}images/previewThumb1.png`
                                  }
                                  width="90"
                                  id="get_file_2"
                                  class="lazy nft__item_preview"
                                  alt=""
                                />
                              )}
                            </div>
                          )}
                        </a>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>

                <FormControlLabel
                  control={
                    <Switch
                      // checked={!true}
                      defaultChecked={putonmarket}
                      onChange={CheckedChange}
                      name="putonsale"
                      id="putonsale"
                    />
                  }
                  label="Put On Sale"
                />
                {putonmarket && (
                  <>
                    {putonmarket && (
                      <nav className="mb-4">
                        <div
                          class="nav nav-tabs de_nav createNavTab"
                          id="nav-tab"
                          role="tablist"
                        >
                          <a
                            onClick={() => {
                              changePutOnSaleType("FixedPrice");
                            }}
                            class={
                              "nav-item nav-link " +
                              (PutOnSaleType == "FixedPrice" ? "active" : "")
                            }
                            id="nav-FixedPrice-tab"
                            data-toggle="tab"
                            href="#nav-fixedPrice"
                            role="tab"
                            aria-controls="nav-fixedPrice"
                            aria-selected={
                              PutOnSaleType == "FixedPrice" ? "true" : "false"
                            }
                          >
                            <span>
                              {/* <i class="fa fa-tag"></i> */}
                              Fixed price
                            </span>
                          </a>
                          {CollectiblesType == "Single" && (
                            <a
                              onClick={() => {
                                changePutOnSaleType("TimedAuction");
                              }}
                              class={
                                "nav-item nav-link " +
                                (PutOnSaleType == "TimedAuction"
                                  ? "active"
                                  : "")
                              }
                              id="nav-TimedAuction-tab"
                              data-toggle="tab"
                              href="#nav-timedAuction"
                              role="tab"
                              aria-controls="nav-timedAuction"
                              aria-selected={
                                PutOnSaleType == "TimedAuction"
                                  ? "true"
                                  : "false"
                              }
                            >
                              <span>
                                {/* <i class="fa fa-hourglass-1"></i> */}
                                Timed auction
                              </span>
                            </a>
                          )}
                          <a
                            onClick={() => {
                              changePutOnSaleType("UnLimitedAuction");
                            }}
                            class={
                              "nav-item nav-link " +
                              (PutOnSaleType == "UnLimitedAuction"
                                ? "active"
                                : "")
                            }
                            id="nav-openForBids-tab"
                            data-toggle="tab"
                            href="#nav-openForBids"
                            role="tab"
                            aria-controls="nav-openForBids"
                            aria-selected={
                              PutOnSaleType == "UnLimitedAuction"
                                ? "true"
                                : "false"
                            }
                          >
                            <span>
                              {/* <i class="fa fa-users"></i> */}
                              Open for bids
                            </span>
                          </a>
                        </div>
                      </nav>
                    )}
                  </>
                )}
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="outlined-basic"
                        name="TokenName"
                        required
                        label="Title"
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="filled-age-native-simple">
                        Category *
                      </InputLabel>
                      <Select
                        native
                        name="category"
                        label="Category"
                        required
                        value={state}
                        onChange={handleChange}
                        inputProps={{
                          name: "category",
                          id: "cat"
                        }}
                        fullWidth
                      >
                        <option aria-label="None" value="" />
                        {CategoryOption?.map((ele) => {
                          return <option value={ele}>{ele}</option>;
                        })}
                      </Select>
                    </FormControl>
                    {PutOnSaleType == "FixedPrice" && putonmarket && (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          id="outlined-basic"
                          required
                          name="Price"
                          label="Price"
                          variant="outlined"
                          onChange={handleChange}
                          fullWidth
                        />
                      </FormControl>
                    )}
                    {PutOnSaleType == "TimedAuction" && (
                      <div
                        class={
                          "tab-pane fade" +
                          (PutOnSaleType == "TimedAuction"
                            ? " show active"
                            : "")
                        }
                        id="nav-timedAuction"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <TextField
                            id="MinimumBid"
                            required
                            // value={TokenRoyalities}
                            name="MinimumBid"
                            label="Minimum Bid"
                            variant="outlined"
                            onChange={inputChange}
                            fullWidth
                          />
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <div className="drobdown_section">
                            <label className="primary_label">
                              Starting date
                            </label>
                            <div class="dropdown">
                              <button
                                class="btn dropdown-toggle filter_btn inp_btn m-0 text-left w-100"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{
                                  border: "1px solid rgb(34 34 34 / 31%)",
                                  padding: "14px",
                                  color: "#5e5e5e"
                                }}
                              >
                                <span id="menuBut">{StartDate}</span>
                              </button>
                              <div
                                class="dropdown-menu filter_menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  id="RightAfterListing"
                                  onClick={() => {
                                    DateChange(
                                      "StartDateDropDown",
                                      "RightAfterListing"
                                    );
                                  }}
                                >
                                  Right after listing
                                </div>
                                <div
                                  id="PickStart"
                                  onClick={() => {
                                    DateChange(
                                      "StartDateDropDown",
                                      "PickStartDate"
                                    );
                                  }}
                                >
                                  Pick specific date
                                </div>
                              </div>
                            </div>
                          </div>
                        </FormControl>
                      </div>
                    )}
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="outlined-basic"
                        required
                        value={TokenRoyalities}
                        name="TokenRoyalities"
                        label="Royalty"
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="outlined-basic"
                        label="TokenDescription"
                        name="TokenDescription"
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth
                      />
                    </FormControl>
                    {PutOnSaleType == "TimedAuction" && (
                      <div
                        class={
                          "tab-pane fade" +
                          (PutOnSaleType == "TimedAuction"
                            ? " show active"
                            : "")
                        }
                        id="nav-timedAuction"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <TextField
                            id="MaximumBid"
                            // value={TokenRoyalities}
                            name="MaximumBid"
                            required
                            label="Maximum Bid"
                            variant="outlined"
                            onChange={inputChange}
                            fullWidth
                          />
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <div className="drobdown_section">
                            <label className="primary_label">
                              Expiration date
                            </label>
                            <div class="dropdown">
                              <button
                                class="btn dropdown-toggle filter_btn inp_btn m-0 text-left w-100"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{
                                  border: "1px solid rgb(34 34 34 / 31%)",
                                  padding: "14px",
                                  color: "#5e5e5e"
                                }}
                              >
                                <span id="menuBut">{EndDate}</span>
                              </button>
                              <div
                                class="dropdown-menu filter_menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "1 Day");
                                  }}
                                >
                                  1 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "3 Day");
                                  }}
                                >
                                  3 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "5 Day");
                                  }}
                                >
                                  5 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "7 Day");
                                  }}
                                >
                                  7 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange(
                                      "EndDateDropDown",
                                      "PickEndDate"
                                    );
                                  }}
                                >
                                  Pick specific date
                                </div>
                              </div>
                            </div>
                          </div>
                        </FormControl>
                      </div>
                    )}
                    {CollectiblesType == "Multiple" && (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          id="outlined-basic"
                          name="TokenQuantity"
                          label="Copies"
                          variant="outlined"
                          onChange={handleChange}
                          fullWidth
                        />
                      </FormControl>
                    )}
                  </GridItem>
                </GridContainer>
                {Loading ? (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={MintCall}
                    >
                      Processing... Please Wait...
                    </Button>
                  </FormControl>
                ) : (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={MintCall}
                    >
                      Create NFT
                    </Button>
                  </FormControl>
                )}
              </CardBody>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
      <Modal
        open={openCal}
        onClose={handleClose1}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyCalender_Open}
      </Modal>
      <Modal
        open={openCalExp}
        onClose={handleClose2}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyCalender_Expire_Open}
      </Modal>
    </div>
  );
}
