module.exports = {
  //Demo
  baseUrl: "https://dopaminenftapi.wealwin.com",

  single_contract: "0x7841DA2CA7cBA260562dC6Ec73d6b9a9354B3731",

  multiple_contract: "0xdDCC6B7bE1cAD5A320dfb3730e22250Ed4D7a598",

  ownerAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",

  network: "97",

  frontUrl: "https://dopaminenft.wealwin.com/",

  API_URL: "https://dopaminenftapi.wealwin.com"

  //--------------------LIVE-----------------------

  // baseUrl: "https://api.satxswap.com",

  // single_contract: "0xc0eCb515891f21628F8e4B7696241B7A59A3CEE7",

  // multiple_contract: "0x28D9E10E464ed5AB1F4fb28e1B2B909bC928896d",

  // ownerAddr: "0x0874D799dF6319a281970674cBCB7B30E4C63e17",

  // network: "1",

  // frontUrl: "https://controls.satxswap.com/",

  // API_URL: "https://api.satxswap.com",

  //--------------------LOCAL-----------------------

  // baseUrl: "http://localhost:2053",

  // single_contract: "0x7841DA2CA7cBA260562dC6Ec73d6b9a9354B3731",

  // multiple_contract: "0xdDCC6B7bE1cAD5A320dfb3730e22250Ed4D7a598",

  // ownerAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",

  // network: "5",

  // frontUrl: "http://localhost:3000/",

  // API_URL: "http://localhost:2053"
};
