import React, { useEffect, useState,Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { deleteToken,getbannercollection,getBidslist,getAllcategory,addbanner,removebanner ,halfAddrShow, deletecategory } from './../../actions/users';
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link, useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import { toastAlert } from '../../lib/toastAlert'
import CardHeader from "components/Card/CardHeader.js";
import * as moment from "moment";
import ReactDatatable from '@ashvin27/react-datatable';
import config from 'lib/config';

const rows = [
   
];


const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Collection Name' },
    { id: 'symbol', numeric: true, disablePadding: false, label: 'Collection Symbol' },
    { id: 'owneraddr', numeric: true, disablePadding: false, label: 'Creator' },
    { id: 'tokenttdate', numeric: true, disablePadding: false, label: 'Action' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 76%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onChangeterm: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
    const classes = useStyles();
    const customStyles = customStyle();
    const history =useHistory()
    const classesSearch = useToolbarStyles();
    const [data, setData] = React.useState(0);
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userDetails, setUser] = useState([]);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [typingTimeout, setTypingTimeout] = useState(0)
    const[responsive,setresponsive]            = useState(true);

    // function editR(id) 
    // {
    //     if(id!='') {
    //         history.push("/TokenView/"+id)
    //     }
        
    // }
    // function lock(){

    // }
    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(value,')()()()()((');
        const self = this;
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setSearchQuery(value)
        console.log(value,'%%%');
        setTypingTimeout(setTimeout(function () {
            getAll({ 'search': value });
        }, 1000))
    }

    const handleRequestSort = (event, property) => {
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    useEffect(() => {
        getAll();
    }, [])


    async function add(iid){
        var data = {
            "id":iid
        }
        var test = await addbanner(data);
        toastAlert('success', "Added Successfully", 'currency')
        getAll();
    }

    async function remove(iid){
        var data = {
            "id":iid
        }
        var test = await removebanner(data);
        toastAlert('success', "Removed Successfully", 'currency')
        getAll();
    }

    const getAll = async (search = {}) => {
        let filterData = search;
        console.log(filterData,'fildata----');
        if (filterData.search == '') {
            filterData = {}
        }
        var test = await getbannercollection();
        console.log(test,"===================testtesttesttestbanner")
        if(test && test.result && test.result.data && test.result.data.RespData){
            setUser(test.result.data.RespData);
        }
    }


    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleDelete = async (iid) => {
        try {
            var data={
                id:iid
            }
            const res = await deleteToken(iid);

            if(res&&res.userValue){
                console.log(res,"===ress=============================")
                toastAlert('success', res.userValue.data.message, 'currency')
                getAll();
            }
        } catch (err) { }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const columns = [
        {
          key: "name",
          text: "Collection Name",
          className: "Collection_Name",
          align: "left",
          sortable: false,
          cell:record=>
          <div>
              {record.name}
          </div>
        },
        {
          key: "symbol",
          text: "Collection Symbol",
          className: "Collection_Symbol",
          align: "left",
          sortable: false,
          cell: record =>
          <div><p>{record.symbol}</p></div>
        },
        {
          key: "owneraddr",
          text: "Creator",
          className: "Creator",
          align: "left",
          sortable: false,
          cell: record =>
          <div><p>{record.owneraddr}</p></div>
        },
        {
            key: "tokenttdate",
            text: "Date",
            className: "Date",
            align: "left",
            sortable: false,
            cell:record=>
               <div><p>{ moment(record.timestamp).format('MMMM,Do YYYY, hh:mm A')}</p></div>
          },
          {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => {
              console.log(record, 'recordssssss');
              const checkin = record.banner;
              console.log(checkin, 'checkineeed');
              if (checkin == '0') {
                var lockVal = 'fa fa-lock';
              } else {
                var lockVal = 'fa fa-unlock';
              }
        
              return (
                <Fragment>
                 {record  && record.banner && record.banner==true ?
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={() => remove(record._id)}
                        style={{ marginRight: '5px' }}>
                        Remove
                    </button>
                    :
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={() => add(record._id)}
                        style={{ marginRight: '5px' }}>
                        Add
                    </button>
                 }
                </Fragment>
              );
            }
          },
      ];

    const configdata = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Users",
            no_data_text: 'No user found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
            defaultSortAsc: true,
        };
        function pageChange(pageData) {
            console.log("OnPageChange", pageData);
        }
        
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
            <Toolbar
                    className={clsx(classesSearch.root, {
                        [classesSearch.highlight]: selected.length > 0,
                    })}
                >
                    <Typography className={classesSearch.title} variant="h6" id="tableTitle" component="div">
                       Collection List
                    </Typography>
                </Toolbar>
            <ReactDatatable
                   responsive={responsive}
                   config={configdata}
                   records={userDetails}
                   columns={columns}
                    onPageChange={pageChange()}
                    />
            </Paper>
        </div>
    );
}