

import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Switch from '@material-ui/core/Switch';
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import config from "../../actions/config.js"  
import Web3 from 'web3';
import '@metamask/legacy-web3'
import single from '../../ABI/ABI.json';
import multiple from '../../ABI/MULTIABI.json';
import { toast } from 'react-toastify';
import { toastAlert } from '../../lib/toastAlert'


import {getcmsdetails , cmsupdate } from "../../actions/users";

var singlesmartContract = config.singlesmartContract;
var multiplesmartContract = config.multiplesmartContract;
var network = config.network;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  royality: "",
  service: "",
  service1: "",
  USD: "",
  bidfee:"",
};

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});

  const dispatch = useDispatch();
  const [headerlogo , setheaderlogo] = useState("");
  const [footerlogo , setfooterlogo] = useState("");
  const [footercontent , setfootercontent] = useState("");
  const [headercontent , setheadercontent] = useState("");
  const [headlogo , setHeadlogo] = useState("");
  const [footlogo , setFootlogo] = useState("");

  useEffect(() => {
    getcms()
  }, [])

  let formdata = {};

  const handlelogoFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = function (e) {
      setHeadlogo(reader.result)
      }.bind(this);
      setheaderlogo(event.target.files[0])
    }
  };

  const handlelogofooterFile = (event) => {
     if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = function (e) {
      setFootlogo(reader.result)
      }.bind(this);
    setfooterlogo(event.target.files[0])
    }
  };

  const handlehcontentchange = (event) => {
    setheadercontent(event.target.value)
  };

  const handlefcontentchange = (event) => {
    setfootercontent(event.target.value)
  };

  async function getcms(){
    var result = await getcmsdetails();
    if(result && result.result){
      setHeadlogo(config.baseUrl+"/cmsimage/"+result.result.headerlogo)
      setFootlogo(config.baseUrl+"/cmsimage/"+result.result.footerlogo)
      setheadercontent(result.result.headercontent)
      setfootercontent(result.result.footercontent)
    }
  }

  async function updatecms(){
   try {
      let reqData = new FormData();
      reqData.append('headerlogo', headerlogo);
      reqData.append('footerlogo', footerlogo);
      reqData.append('headercontent', headercontent);
      reqData.append('footercontent', footercontent);
      var result = await cmsupdate(reqData);
      toastAlert('success', "Successfully Updated", 'AddCms')
    } catch (err) {
    }
  }

  console.log(headercontent,"===================")

  return (
    <div>
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <form className={classes.form} noValidate >
                  <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Cms Update</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="headerlogo"
                          name="headerlogo"
                          onChange={handlelogoFile}
                          type="file"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        <img src={headlogo} width="50" height="30" />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="footerlogo"
                          name="footerlogo"
                          onChange={handlelogofooterFile}
                          type="file"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        <img src={footlogo} width="50" height="30" />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="headercontent"
                          name="headercontent"
                          onChange={handlehcontentchange}
                          type="text"
                          value = {headercontent}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="footercontent"
                          name="footercontent"
                          onChange={handlefcontentchange}
                          value = {footercontent}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    </CardBody>
                  <CardFooter>
                    <Button color="primary" onClick={()=>updatecms()} type="button">Update</Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
