// import axios
import axios from "axios";

// import lib
import config from "../lib/config";
import { getAuthToken } from "../lib/localStorage";

axios.defaults.headers.common["Authorization"] = getAuthToken();

export const getDashboardCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/dashboardCount`,
    });
    console.log(respData,"resssssDashboardddddddddddddddddddd");
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAdminRenewal = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getAdminRenewal`,
    });
    console.log(respData,'respData');
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getDashboardDetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getDashboardDetails`,
    });
    console.log(respData,'respData');
    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getTopCollection = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getTopCollection`,
    });
    console.log(respData,'respData12');
    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getTradeCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/totalCount`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      // error: err.response.data.errors
    };
  }
};

export const getTokenCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/tokenCount`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getBidderCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/bidderCount`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
