import {
    SET_AUTHENTICATION,
    PROFILE_UPDATE
} from "../constant";

const initialState = {
    isAuth: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHENTICATION:
            return {
                ...state,
                ...action.authData
            };
            case PROFILE_UPDATE:
                return {
                    ...state,
                    profile: action.payload
                }; 
        default:
            return state;
    }
}

export default authReducer; 