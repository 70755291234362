

import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toastAlert } from "../../lib/toastAlert";
import Web3 from 'web3';
import config from "../../actions/config.js" 
import BEP721 from '../../ABI/ABI.json';
import BEP1155 from '../../ABI/BEP1155.json';
import isEmpty from "../../lib/isEmpty";
import { updatefeeSetting,getfees } from "../../actions/users";
var smartContract = config.smartContract;
var network       = config.network;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  royality: "",
  service: "",
  USD: "",
  bidfee:"",
  singlefees:"",
  wbnbfee:"",
  escfee:"",
  multiplefees:"",
  multiplewbnbfee:"",
  multipleescfee:"",
  bouiusd:"",
  busdfee:"",
  discount:"",
  mdiscount:"",
  mtokenlimit:"",
  tokenlimit:""
};

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});

  const dispatch = useDispatch();
  const [userdet, setUser]                = useState();
  const [formValue, setFormValue]         = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [owneraddr, setowneraddr]         = useState("");
  const [process,setprocess]              = useState("init");
  const [singleprocess,setsingleprocess]  = useState("init");
  const [wbnbprocess,setwbnbprocess]      = useState("init");
  const [busdprocess,setbusdprocess]      = useState("init");
  const [bouiusdprocess,setbouiusdprocess]            = useState("init");
  const [multiplewbnbprocess,setmultiplewbnbprocess]  = useState("init");
  const [escprocess,setescprocess]                    = useState("init")
  const [multipleescprocess,setmultipleescprocess]    = useState("init")
  const [limitprocess , setlimitprocess ]             = useState("init")
  const [mlimitprocess , setmlimitprocess ]           = useState("init")
  const [discountprocess , setdiscountprocess ]       = useState("init")
  const [mdiscountprocess , setmdiscountprocess ]     = useState("init")
  const [royalper, setroyalper]                       = useState(0);

  const { userId } = useParams();

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
  };

  const onChange1 = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setroyalper(value);
  };

  const { mdiscount, mtokenlimit, royality, service, USD,bouiusd,busdfee,bidfee,singlefees,wbnbfee,escfee,multiplewbnbfee,multiplefees,multipleescfee,discount,tokenlimit} = formValue;

  useEffect(() => {
    getdiscount();
    getSettingdet();
    setTimeout(function(){
      getRoyalty();
    },500)
  }, [])

  async function usdValidation(chk){
    if(chk){
      var ValidateError = {};
      if(bouiusd==''){
        ValidateError.bouiusd = 'Fee is not allow to empty'
      }
      setValidateError(ValidateError);
      return ValidateError;
    }else{
      return {};
    }
  }

  async function getdiscount(){
    
  }

  async function usdvalUpdated(){
    try{
      var errors     = await usdValidation(true);
      var errorsSize = Object.keys(errors).length;
         if(errorsSize != 0) {
           setValidateError(errors)
           return false
         }else{
          setbouiusdprocess("processing")
          var type="usdvalue"
          var payload={
            bouiusd,
            type
          }
          const data = await updatefeeSetting(payload);
          toastAlert("success", "Successfully updated", "category");
          setbouiusdprocess("done")
          setTimeout(() => { window.location.reload(false) }, 2000);  
         }
    }catch(err){}
  }
  async function busdfeeValidation(chk){
    if(chk){
      var ValidateError = {};
      if(busdfee==''){
        ValidateError.busdfee = 'Fee is not allow to empty'
      }
      setValidateError(ValidateError);
      return ValidateError;
    }else{
      return {};
    }
  }
  async function Changebusdfee(){
    try{
      var errors     = await busdfeeValidation(true);
      var errorsSize = Object.keys(errors).length;
         if(errorsSize != 0) {
           setValidateError(errors)
           return false
         }else{
          setbusdprocess("processing")
          var web3 = new Web3(window.ethereum);   
          if(window.web3.currentProvider.networkVersion == network){     
           await window.ethereum.enable();
           var web3  = new Web3(window.web3.currentProvider);
           var BUSD = new web3.eth.Contract(BEP721,config.single_contract);
           var currAddr = await web3.eth.getAccounts();
           var type="single";
           var token="busd";
           let payload ={
             busdfee,
             type,
             token
           }
           var value = ( busdfee*1000000000000000000).toString()
           let ownaddress = await BUSD.methods.getOwner().call()
           if(currAddr[0]==ownaddress){
             try{
               var result = await BUSD.methods.busdserviceFunction(
                 value         
             ).send({
                   from: currAddr[0]
               }).catch((error) => {
                 toastAlert("error", "Failed", "fee");
                 setTimeout(() => { window.location.reload(false) }, 2000);  
             })
             if(result && result.status == true){
               const data = await updatefeeSetting(payload);
                 toastAlert("success", "Successfully updated", "category");
                 setmultipleescprocess("done");
                 setTimeout(() => { window.location.reload(false) }, 2000);  
             }
             }catch(err){}
         }
         else{
           toastAlert("error", "Please  Connect with Owner Wallet", "fee"); 
           setTimeout(() => { window.location.reload(false) }, 2000);  
         }
       }else{
         toastAlert("error", "Please choose binance network", "fee");
         setTimeout(() => { window.location.reload(false) }, 2000);  
       }

         }
    }catch(err){}
  }
  async function getSettingdet(){
    try{
      var web3 = new Web3(window.ethereum);
      var singlesd = new web3.eth.Contract(BEP721,config.single_contract);
      var singlediscount = await singlesd.methods.discountPercentage().call()
      singlediscount = parseFloat(singlediscount)/1000000000000000000
      var singlelimit = await singlesd.methods.minimumTokenLimit().call()
      singlelimit = parseFloat(singlelimit)/1000000000000000000
      var  multicont= new web3.eth.Contract(BEP1155,config.multiple_contract);
      var multiplesdh = await multicont.methods.discountPercentage().call()
      multiplesdh = parseFloat(multiplesdh)/1000000000000000000
      var multiplelimit = await multicont.methods.minimumTokenLimit().call()
      multiplelimit = parseFloat(multiplelimit)/1000000000000000000
      var {result} = await getfees();
      console.log("getsettinglist",result)
      if(result&&result.data){
        let data = {
          'singlefees':result.data.singleservicefee,
          'wbnbfee':result.data.wbnbfee,
          'escfee':result.data.singleescfee,
          'multiplefees':result.data.multipleservicefee,
          'multiplewbnbfee':result.data.multiplebusdfee,
          'multipleescfee':result.data.multipleescfee,
          'bouiusd':result.data.bouiusd,
          'busdfee':result.data.busdfee,
          'discount':singlediscount,
          'mdiscount':multiplesdh,
          'tokenlimit':singlelimit,
          'mtokenlimit':multiplelimit
        }
        setFormValue(data)
      }
    }catch(err){}
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(formValue);

    let reqData = {
      royality,
      service,
      USD,
      wbnbfee,
      singlefees,
      multiplefees,
      escfee,
      
    };
    
    var data = await updatefeeSetting(reqData);
    if (data.result && data.result.message && data.result.message._id) {
      toastAlert("success", "Successfully updated", "category");
      setTimeout(() => { window.location.reload(false) }, 2000);  
    } else {
      toastAlert("error", data.error.message, "category");
      setTimeout(() => { window.location.reload(false) }, 2000);  
    }
  };
  const changeFee = async (e) => {

    var feeVal = parseFloat(royalper);
    if(feeVal && feeVal > 0){
      if(window.web3.currentProvider.networkVersion == network){

        var web3 = new Web3(window.ethereum);
        
        await window.ethereum.enable();
        var web3  = new Web3(window.web3.currentProvider);
        var nftContract = new web3.eth.Contract(BEP721,config.single_contract);
        var currAddr = await web3.eth.getAccounts();
        console.log(currAddr[0],owneraddr)
        let ownaddress = await nftContract.methods.getOwner().call()
        if(currAddr[0]==ownaddress){
          var result = await nftContract.methods.changeroyaltypercentage(
            feeVal
          ).send({
            from: currAddr[0]
          });
  
          toastAlert("success", "Successfully changed", "fee");   
          setTimeout(() => { window.location.reload(false) }, 2000);  
        }else{
          toastAlert("error", "Please login "+config.owneraddr, "fee");
          setTimeout(() => { window.location.reload(false) }, 2000);  
        }
       
    }else{
      toastAlert("error", "Please choose binance network", "fee");
      setTimeout(() => { window.location.reload(false) }, 2000);  
    }   
    }else{
      toastAlert("error", "Please enter the percentage", "fee");
      setTimeout(() => { window.location.reload(false) }, 2000);  
    }

  }

  async function getRoyalty(){
    if(window.web3.currentProvider.networkVersion == network){
      var web3 = new Web3(window.ethereum);
  }
  }
  async function discountValidation(chk){
    if(chk){
      var ValidateError = {};
      if(singlefees==''){
        ValidateError.singlefees = 'Fee is not allow to empty'
      }
      setValidateError(ValidateError);
      return ValidateError;
    }else{
      return {};
    }
  }  
  async function escValidation(chk){
    if(chk){
      var ValidateError = {};
      if(escfee==''||escfee==undefined){
        ValidateError.escfee = 'Fee is not allow to empty'
      }
      setValidateError(ValidateError);
      return ValidateError;
    }else{
      return {};
    }
  }
  async function multipleescValidation(chk){
    if(chk){
      var ValidateError = {};
      if(multipleescfee==''||multipleescfee==undefined){
        ValidateError.multipleescfee = 'Fee is not allow to empty'
      }
      setValidateError(ValidateError);
      return ValidateError;
    }else{
      return {};
    }
  }
  const Changeescfee = async (e) => {
    var errors     = await escValidation(true);
   var errorsSize = Object.keys(errors).length;
      if(errorsSize != 0) {
        setValidateError(errors)
        return false
      }else{
        setescprocess("processing")
       var web3 = new Web3(window.ethereum);   
       if(window.web3.currentProvider.networkVersion == network){     
        await window.ethereum.enable();
        var web3  = new Web3(window.web3.currentProvider);
        var Wbnb = new web3.eth.Contract(BEP721,config.single_contract);
        var currAddr = await web3.eth.getAccounts();
        var type="single";
        var token="esc";
        let payload ={
          escfee,
          type,
          token
        }
        var value = (escfee*1000000000000000000).toString()
        let ownaddress = await Wbnb.methods.getOwner().call();
        if(currAddr[0]==ownaddress){
          try{
            var result = await Wbnb.methods.serviceFunctionESC(
              value         
          ).send({
                from: currAddr[0]
            }).catch((error) => {
              toastAlert("error", "Failed", "fee");
              setTimeout(() => { window.location.reload(false) }, 2000);  
          })
          if(result && result.status == true){
            const data = await updatefeeSetting(payload);
              toastAlert("success", "Successfully updated", "category");
              setescprocess("done");
              setTimeout(() => { window.location.reload(false) }, 2000);  
          }
          }catch(err){}
      }
      else{
        toastAlert("error", "Please  Connect with Owner Wallet", "fee"); 
        setTimeout(() => { window.location.reload(false) }, 2000);  
      }
    }else{
      toastAlert("error", "Please choose binance network", "fee");
      setTimeout(() => { window.location.reload(false) }, 2000);  
    }
  }
  }
  const multipleChangeescfee = async (e) => {
    var errors     = await multipleescValidation(true);
   var errorsSize = Object.keys(errors).length;
      if(errorsSize != 0) {
        setValidateError(errors)
        return false
      }else{
        setmultipleescprocess("processing")
       var web3 = new Web3(window.ethereum);   
       if(window.web3.currentProvider.networkVersion == network){     
        await window.ethereum.enable();
        var web3  = new Web3(window.web3.currentProvider);
        var Wbnb = new web3.eth.Contract(BEP1155,config.multiple_contract);
        var currAddr = await web3.eth.getAccounts();
        var type="multiple";
        var token="esc";
        let payload ={
          multipleescfee,
          type,
          token
        }
        var value = ( multipleescfee*1000000000000000000).toString()
        let ownaddress = await Wbnb.methods.getOwner().call()
        if(currAddr[0]==ownaddress){
          try{
            var result = await Wbnb.methods.serviceFunctionESC(
              value         
          ).send({
                from: currAddr[0]
            }).catch((error) => {
              toastAlert("error", "Failed", "fee");
              setTimeout(() => { window.location.reload(false) }, 2000);  
          })
          if(result && result.status == true){
            const data = await updatefeeSetting(payload);
              toastAlert("success", "Successfully updated", "category");
              setmultipleescprocess("done")
              setTimeout(() => { window.location.reload(false) }, 2000);  
          }
          }catch(err){}
      }
      else{
        toastAlert("error", "Please  Connect with Owner Wallet", "fee"); 
        setTimeout(() => { window.location.reload(false) }, 2000);  
      }
    }else{
      toastAlert("error", "Please choose binance network", "fee");
      setTimeout(() => { window.location.reload(false) }, 2000);  
    }
  }
  }

async function singleContract(){
  var errors     = await discountValidation(true);
  var errorsSize = Object.keys(errors).length;
      if(errorsSize != 0) {
        setValidateError(errors)
        return false
      }else{
        setsingleprocess("processing")
        var type="single";
        let payload ={
          singlefees,
          type
        }
        if(window.web3.currentProvider.networkVersion == network){

          var web3 = new Web3(window.ethereum);
          
          await window.ethereum.enable();
          var web3  = new Web3(window.web3.currentProvider);
          var nftContract = new web3.eth.Contract(BEP721,config.single_contract);
          var currAddr = await web3.eth.getAccounts();
          let ownaddress = await nftContract.methods.getOwner().call()
          var fee=(singlefees*1000000000000000000)
         console.log(currAddr[0],"==========",ownaddress,"===============ownaddresssssssssss")
          if(currAddr[0]==ownaddress){
            try{
              var result = await nftContract.methods.serviceFunction(
                fee.toString()
              ).send({
                from: currAddr[0]
              });
            if(result && result.status == true){
            const data = await updatefeeSetting(payload);
            toastAlert("success", "Successfully updated", "category");
            setsingleprocess("done");
            setTimeout(() => { window.location.reload(false) }, 2000);  
            }
            }catch(err){
              console.log("err_err",err)
              setsingleprocess("tryagain")
              toastAlert("error", "Failed", "fee");
              // setTimeout(() => { window.location.reload(false) }, 2000);  
            }
          }else{
            toastAlert("error", "Please login "+config.ownerAddr, "fee");
            // setTimeout(() => { window.location.reload(false) }, 2000);  
          }
         
      }else{
        toastAlert("error", "Please choose binance network", "fee");
        setTimeout(() => { window.location.reload(false) }, 2000);  
      } 
      }
   }
   async function multipleValidation(chk){
    if(chk){
      var ValidateError = {};
      if(multiplefees==''){
        ValidateError.multiplefees = 'Fee is not allow to empty'
      }
      setValidateError(ValidateError);
      return ValidateError;
    }else{
      return {};
    }
  }

  async function discountContract(){
        if(discount && discount!="" && discount!=null && discount!=undefined && parseFloat(discount)>0)
        {
          setdiscountprocess("processing")
          if (window.ethereum) { 
           try { 
            if (typeof web3 !== 'undefined') { 
              window.ethereum.enable().then(async function() { 
              const web3  = new Web3(window.web3.currentProvider)  
              if (window.web3.currentProvider.isMetaMask === true) { 
                await web3.eth.getAccounts(async function(error, result) { 
                 if(window.web3.currentProvider.networkVersion == network){ 
                  var percent =(parseFloat(discount)*1000000000000000000).toString()
                     var logaddress =result[0];  
                     var isAddress=web3.utils.isAddress(logaddress);
                     if(isAddress==false){
                      setdiscountprocess("tryagain")
                      toastAlert('error', "Please enter the valid address", 'address')
                      return false;
                     }
                     console.log(BEP721,config.single_contract,"===============")
                     const singlenftContract = new web3.eth.Contract(BEP721,config.single_contract); 
                     var owner = await singlenftContract.methods.getOwner().call();
                     if(owner==logaddress){  
                        singlenftContract.methods.setDiscountPercentage(percent).send({ from: logaddress })  
                        .then(async (result) => {  
                          toastAlert('success', "Updated Successfully", 'address')
                          setdiscountprocess("done")
                           window.location.reload();
                       }).catch((error) => { 
                         setdiscountprocess("tryagain") 
                           toast.error('Failed Try Again Later', toasterOption) 
                        }) 
                      }else{
                        setdiscountprocess("tryagain")
                        toastAlert('error', "Please Login Admin Address", 'address')
                      }
                    }else{
                      setdiscountprocess("tryagain")
                      toastAlert('error', network +"Please Choose Mainnet"+window.web3.currentProvider.network, 'address')
                    }
                  })
                }else{
                  setdiscountprocess("tryagain")
                  toastAlert('error', "Please Add Metamask External", 'address')
                }
              })
            }else{
              setdiscountprocess("tryagain")
              toastAlert('error', "Please Add Metamask External", 'address')
            }

            }catch(err){ 
              setdiscountprocess("tryagain")
              toast.error('Failed Try Again Later', toasterOption) 
            } 
         }else{
            setdiscountprocess("tryagain")
            toastAlert('error', "Please Add Metamask External", 'address')
         }
          
      }else{
        toastAlert("error", "Please Enter Valid Discount", "fee");
        setdiscountprocess("tryagain")
        setTimeout(() => { window.location.reload(false) }, 2000); 
      }
  }

  async function mdiscountContract(){
        if(mdiscount && mdiscount!="" && mdiscount!=null && mdiscount!=undefined && parseFloat(mdiscount)>0)
        {
          setmdiscountprocess("processing")
          if (window.ethereum) { 
           try { 
            if (typeof web3 !== 'undefined') { 
              window.ethereum.enable().then(async function() { 
              const web3  = new Web3(window.web3.currentProvider)  
              if (window.web3.currentProvider.isMetaMask === true) { 
                await web3.eth.getAccounts(async function(error, result) { 
                 if(window.web3.currentProvider.networkVersion == network){ 
                  var percent =(parseFloat(mdiscount)*1000000000000000000).toString()
                     var logaddress =result[0];  
                     var isAddress=web3.utils.isAddress(logaddress);
                     if(isAddress==false){
                      setmdiscountprocess("tryagain")
                      toastAlert('error', "Please enter the valid address", 'address')
                      return false;
                     }
                     const multiplenftContract = new web3.eth.Contract(BEP1155,config.multiple_contract);
                     var owner = await multiplenftContract.methods.getOwner().call();
                     if(owner==logaddress){  
                          multiplenftContract.methods.setDiscountPercentage(percent).send({ from: logaddress })  
                        .then(async (result1) => {
                          toastAlert('success', "Updated Successfully", 'address')
                          setmdiscountprocess("done")
                           window.location.reload();
                          }).catch((error) => { 
                           setmdiscountprocess("tryagain")
                           toast.error('Failed Try Again Later', toasterOption) 
                        }) 
                      }else{
                        setmdiscountprocess("tryagain")
                        toastAlert('error', "Please Login Admin Address", 'address')
                      }
                    }else{
                      setmdiscountprocess("tryagain")
                      toastAlert('error', network +"Please Choose Mainnet"+window.web3.currentProvider.network, 'address')
                    }
                  })
                }else{
                  setmdiscountprocess("tryagain")
                  toastAlert('error', "Please Add Metamask External", 'address')
                }
              })
            }else{
              setmdiscountprocess("tryagain")
              toastAlert('error', "Please Add Metamask External", 'address')
            }

            }catch(err){ 
              setmdiscountprocess("tryagain")
              toast.error('Failed Try Again Later', toasterOption) 
            } 
         }else{
            setmdiscountprocess("tryagain")
            toastAlert('error', "Please Add Metamask External", 'address')
         }
          
      }else{
        toastAlert("error", "Please Enter Valid Discount", "fee");
        setmdiscountprocess("tryagain")
        setTimeout(() => { window.location.reload(false) }, 2000); 
      }
  }

  async function limitchange(){
     if(tokenlimit && tokenlimit!="" && tokenlimit!=null && tokenlimit!=undefined && parseFloat(tokenlimit)>0)
        {
          setlimitprocess("processing")
          if (window.ethereum) { 
           try { 
            if (typeof web3 !== 'undefined') { 
              window.ethereum.enable().then(async function() { 
              const web3  = new Web3(window.web3.currentProvider)  
              if (window.web3.currentProvider.isMetaMask === true) { 
                await web3.eth.getAccounts(async function(error, result) { 
                 if(window.web3.currentProvider.networkVersion == network){ 
                  var percent =(parseFloat(tokenlimit)*1000000000000000000).toString()
                     var logaddress =result[0];  
                     var isAddress=web3.utils.isAddress(logaddress);
                     if(isAddress==false){
                      setlimitprocess("tryagain")
                      toastAlert('error', "Please enter the valid address", 'address')
                      return false;
                     }
                     const singlenftContract = new web3.eth.Contract(BEP721,config.single_contract); 
                     var owner = await singlenftContract.methods.getOwner().call();
                     if(owner==logaddress){  
                        singlenftContract.methods.setTokenLimit(percent).send({ from: logaddress })  
                        .then(async (result) => {  
                          toastAlert('success', "Updated Successfully", 'address')
                          setlimitprocess("done")
                           window.location.reload();
                       }).catch((error) => { 
                         setlimitprocess("tryagain") 
                           toast.error('Failed Try Again Later', toasterOption) 
                        }) 
                      }else{
                        setlimitprocess("tryagain")
                        toastAlert('error', "Please Login Admin Address", 'address')
                      }
                    }else{
                      setlimitprocess("tryagain")
                      toastAlert('error', network +"Please Choose Mainnet"+window.web3.currentProvider.network, 'address')
                    }
                  })
                }else{
                  setlimitprocess("tryagain")
                  toastAlert('error', "Please Add Metamask External", 'address')
                }
              })
            }else{
              setlimitprocess("tryagain")
              toastAlert('error', "Please Add Metamask External", 'address')
            }

            }catch(err){ 
              setlimitprocess("tryagain")
              toast.error('Failed Try Again Later', toasterOption) 
            } 
         }else{
            setlimitprocess("tryagain")
            toastAlert('error', "Please Add Metamask External", 'address')
         }
          
      }else{
        toastAlert("error", "Please Enter Valid Limit", "fee");
        setlimitprocess("tryagain")
        setTimeout(() => { window.location.reload(false) }, 2000); 
      }
  }

  async function mlimitchange(){
     if(mtokenlimit && mtokenlimit!="" && mtokenlimit!=null && mtokenlimit!=undefined && parseFloat(mtokenlimit)>0)
        {
          setmlimitprocess("processing")
          if (window.ethereum) { 
           try { 
            if (typeof web3 !== 'undefined') { 
              window.ethereum.enable().then(async function() { 
              const web3  = new Web3(window.web3.currentProvider)  
              if (window.web3.currentProvider.isMetaMask === true) { 
                await web3.eth.getAccounts(async function(error, result) { 
                 if(window.web3.currentProvider.networkVersion == network){ 
                  var percent =(parseFloat(mtokenlimit)*1000000000000000000).toString()
                     var logaddress =result[0];  
                     var isAddress=web3.utils.isAddress(logaddress);
                     if(isAddress==false){
                      setmlimitprocess("tryagain")
                      toastAlert('error', "Please enter the valid address", 'address')
                      return false;
                     }
                     const multiplenftContract = new web3.eth.Contract(BEP1155,config.multiple_contract);
                     var owner = await multiplenftContract.methods.getOwner().call();
                     if(owner==logaddress){  
                          multiplenftContract.methods.setTokenLimit(percent).send({ from: logaddress })  
                        .then(async (result1) => {
                          toastAlert('success', "Updated Successfully", 'address')
                          setmlimitprocess("done")
                           window.location.reload();
                           
                          }).catch((error) => { 
                           setmlimitprocess("tryagain")
                           toast.error('Failed Try Again Later', toasterOption) 
                        }) 
                      }else{
                        setmlimitprocess("tryagain")
                        toastAlert('error', "Please Login Admin Address", 'address')
                      }
                    }else{
                      setmlimitprocess("tryagain")
                      toastAlert('error', network +"Please Choose Mainnet"+window.web3.currentProvider.network, 'address')
                    }
                  })
                }else{
                  setmlimitprocess("tryagain")
                  toastAlert('error', "Please Add Metamask External", 'address')
                }
              })
            }else{
              setmlimitprocess("tryagain")
              toastAlert('error', "Please Add Metamask External", 'address')
            }

            }catch(err){ 
              setmlimitprocess("tryagain")
              toast.error('Failed Try Again Later', toasterOption) 
            } 
         }else{
            setmlimitprocess("tryagain")
            toastAlert('error', "Please Add Metamask External", 'address')
         }
          
      }else{
        toastAlert("error", "Please Enter Valid Limit", "fee");
        setmlimitprocess("tryagain")
        setTimeout(() => { window.location.reload(false) }, 2000); 
      }
  }

   async function multipleContract(){
    var errors     = await multipleValidation(true);
    var errorsSize = Object.keys(errors).length;
        if(errorsSize != 0) {
          setValidateError(errors)
          return false
        }else{
          setprocess("processing")
          var type="multiple";
          let payload ={
            multiplefees,
            type
          }
          if(window.web3.currentProvider.networkVersion == network){
            var web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
            var web3  = new Web3(window.web3.currentProvider);
            var nftContract = new web3.eth.Contract(BEP1155,config.multiple_contract);
            var currAddr = await web3.eth.getAccounts();
            console.log('2121221',currAddr[0],owneraddr)
            var fee=(multiplefees*1000000000000000000)
            let ownaddress = await nftContract.methods.getOwner().call()
            if(currAddr[0]==ownaddress){
              try{
                var result = await nftContract.methods.serviceFunction(
                  fee.toString()
                ).send({
                  from: currAddr[0]
                });
                const data = await updatefeeSetting(payload);
                toastAlert("success", "Successfully updated", "category");
                setprocess("done");
                setTimeout(() => { window.location.reload(false) }, 2000);  
              }catch(err){
                setprocess("tryagain")
                toastAlert("error", "Failed", "fee");
                setTimeout(() => { window.location.reload(false) }, 2000);  
              }
            }else{
              toastAlert("error", "Please login "+config.ownerAddr, "fee");
              setTimeout(() => { window.location.reload(false) }, 2000);  
            }
           
        }else{
          toastAlert("error", "Please choose binance network", "fee");
          setTimeout(() => { window.location.reload(false) }, 2000);  
        } 
        }
     }
  let formdata = {};

  return (
    <div>
      <div id="page-content-wrapper">
        <div className="container-fluid">

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <form className={classes.form}></form>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Settings(Single)</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                      <label>ETH And WETH Fee</label> 
                        <input
                          type="number" 
                          min="0"
                          name="singlefees"
                          id="singlefees"
                          class="form-control"
                          placeholder="Please enter the value"
                          aria-label="bid"
                          aria-describedby="basic-addon2"
                          onChange={onChange}
                          value={singlefees}
                          autoComplete="off"
                          onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
                          />
                      {validateError.singlefees && <span className="text-danger" >{validateError.singlefees}</span>}
                      
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                  <Button color="primary" type="button"
                  disabled={(singleprocess=='processing' || singleprocess=='done')}
                  onClick={singleContract}
                    >
                    {singleprocess == 'processing' && 
                      <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                    }
                    {singleprocess == 'init' && 'Submit'}
                    {singleprocess == 'processing' && 'In-progress...'}
                    {singleprocess == 'done' && 'Done'}
                    {singleprocess == 'tryagain' && 'Try Again'}
                   </Button>
                </CardFooter>
               

                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                      {/* <label>Token Fee for ESC</label> 
                        <input
                          type="number" 
                          min="0"
                          name="escfee"
                          id="escfee"
                          class="form-control"
                          placeholder="Please enter the value"
                          aria-label="bid"
                          aria-describedby="basic-addon2"
                          onChange={onChange}
                          value={escfee}
                          autoComplete="off"
                          onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
                          />
                {validateError.escfee && <span className="text-danger" >{validateError.escfee}</span>} */}

                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                
              {/* <Button color="primary" type="button"
                  disabled={(escprocess=='processing' || escprocess=='done')}
                  onClick={Changeescfee}
                    >
                {escprocess == 'processing' && 
                  <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                }
                {escprocess == 'init' && 'Submit'}
                {escprocess == 'processing' && 'In-progress...'}
                {escprocess == 'done' && 'Done'}
                {escprocess == 'tryagain' && 'Try Again'}
            </Button> */}
                  </CardFooter>
              </Card>
              {/* multiple */}
              <Card>
                <form className={classes.form}></form>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Settings(Multiple)</h4>
                  </CardHeader>
                  <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                        <label>ETH And WETH Fee</label> 
                          <input
                          type="number" 
                          min="0"
                          name="multiplefees"
                          id="multiplefees"
                          class="form-control"
                          placeholder="Please enter the value"
                          aria-label="bid"
                          aria-describedby="basic-addon2"
                          onChange={onChange}
                          value={multiplefees}
                          autoComplete="off"
                          onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
                          />
                        {validateError.multiplefees && <span className="text-danger" >{validateError.multiplefees}</span>}

                        </GridItem>

                      </GridContainer>

                      </CardBody>
                      <CardFooter>
                      <Button color="primary" type="submit"
                      disabled={(process=='processing' || process=='done')}
                      onClick={multipleContract}
                      >
                      {process == 'processing' && 
                      <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                      }
                      {process == 'init' && 'Submit'}
                      {process == 'processing' && 'In-progress...'}
                      {process == 'done' && 'Done'}
                      {process == 'tryagain' && 'Try Again'}
                      </Button>
                      </CardFooter>
             
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                      {/* <label>Token Fee for ESC</label> 
                         <input
                            type="number" 
                            min="0"
                            name="multipleescfee"
                            id="multipleescfee"
                            class="form-control"
                            placeholder="Please enter the value"
                            aria-label="bid"
                            aria-describedby="basic-addon2"
                            onChange={onChange}
                            value={multipleescfee}
                            autoComplete="off"
                            onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
                            />
                {validateError.multipleescfee && <span className="text-danger" >{validateError.multipleescfee}</span>} */}

                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                
              {/* <Button color="primary" type="button"
                  disabled={(multipleescprocess=='processing' || multipleescprocess=='done')}
                  onClick={multipleChangeescfee}
                    >
                {multipleescprocess == 'processing' && 
                  <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                }
                {multipleescprocess == 'init' && 'Submit'}
                {multipleescprocess == 'processing' && 'In-progress...'}
                {multipleescprocess == 'done' && 'Done'}
                {multipleescprocess == 'tryagain' && 'Try Again'}
            </Button> */}
                  </CardFooter>
              </Card>

            {/*discountupdate*/}

             {/* <Card>
                <form className={classes.form}></form>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Settings(Single Discount)</h4>
                  </CardHeader>
                  <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                        <label>Discount</label> 
                          <input
                          type="number" 
                          min="0"
                          name="discount"
                          id="discount"
                          class="form-control"
                          placeholder="Please enter the value"
                          aria-label="bid"
                          aria-describedby="basic-addon2"
                          onChange={onChange}
                          value={discount}
                          autoComplete="off"
                          onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
                          />
                        {validateError.discount && <span className="text-danger" >{validateError.discount}</span>}

                        </GridItem>

                      </GridContainer>

                      </CardBody>
                      <CardFooter>
                      <Button color="primary" type="submit"
                      disabled={(discountprocess=='processing' || discountprocess=='done')}
                      onClick={discountContract}
                      >
                      {discountprocess == 'processing' && 
                      <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                      }
                      {discountprocess == 'init' && 'Submit'}
                      {discountprocess == 'processing' && 'In-progress...'}
                      {discountprocess == 'done' && 'Done'}
                      {discountprocess == 'tryagain' && 'Try Again'}
                      </Button>
                      </CardFooter>
             
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                      <label>Token Limit</label> 
                         <input
                            type="number" 
                            min="0"
                            name="tokenlimit"
                            id="tokenlimit"
                            class="form-control"
                            placeholder="Please enter the value"
                            aria-label="bid"
                            aria-describedby="basic-addon2"
                            onChange={onChange}
                            value={tokenlimit}
                            autoComplete="off"
                            onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
                            />
                {validateError.tokenlimit && <span className="text-danger" >{validateError.tokenlimit}</span>}

                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                
              <Button color="primary" type="button"
                  disabled={(limitprocess=='processing' || limitprocess=='done')}
                  onClick={limitchange}
                    >
                {limitprocess == 'processing' && 
                  <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                }
                {limitprocess == 'init' && 'Submit'}
                {limitprocess == 'processing' && 'In-progress...'}
                {limitprocess == 'done' && 'Done'}
                {limitprocess == 'tryagain' && 'Try Again'}
            </Button>
                  </CardFooter>
              </Card> */}


              {/* <Card>
                <form className={classes.form}></form>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Settings(Multiple Discount)</h4>
                  </CardHeader>
                  <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                        <label>Discount</label> 
                          <input
                          type="number" 
                          min="0"
                          name="mdiscount"
                          id="mdiscount"
                          class="form-control"
                          placeholder="Please enter the value"
                          aria-label="bid"
                          aria-describedby="basic-addon2"
                          onChange={onChange}
                          value={mdiscount}
                          autoComplete="off"
                          onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
                          />
                        {validateError.mdiscount && <span className="text-danger" >{validateError.mdiscount}</span>}

                        </GridItem>

                      </GridContainer>

                      </CardBody>
                      <CardFooter>
                      <Button color="primary" type="submit"
                      disabled={(mdiscountprocess=='processing' || mdiscountprocess=='done')}
                      onClick={mdiscountContract}
                      >
                      {mdiscountprocess == 'processing' && 
                      <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                      }
                      {mdiscountprocess == 'init' && 'Submit'}
                      {mdiscountprocess == 'processing' && 'In-progress...'}
                      {mdiscountprocess == 'done' && 'Done'}
                      {mdiscountprocess == 'tryagain' && 'Try Again'}
                      </Button>
                      </CardFooter>
             
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                      <label>Token Limit</label> 
                         <input
                            type="number" 
                            min="0"
                            name="mtokenlimit"
                            id="mtokenlimit"
                            class="form-control"
                            placeholder="Please enter the value"
                            aria-label="bid"
                            aria-describedby="basic-addon2"
                            onChange={onChange}
                            value={mtokenlimit}
                            autoComplete="off"
                            onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
                            />
                {validateError.mtokenlimit && <span className="text-danger" >{validateError.mtokenlimit}</span>}

                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                
              <Button color="primary" type="button"
                  disabled={(mlimitprocess=='processing' || mlimitprocess=='done')}
                  onClick={mlimitchange}
                    >
                {mlimitprocess == 'processing' && 
                  <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                }
                {mlimitprocess == 'init' && 'Submit'}
                {mlimitprocess == 'processing' && 'In-progress...'}
                {mlimitprocess == 'done' && 'Done'}
                {mlimitprocess == 'tryagain' && 'Try Again'}
            </Button>
                  </CardFooter>
              </Card> */}




            </GridItem>
            
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
